import { FormGroup, Input, Label, Select } from '@expressable/ui-library';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';
import { languageOptions, specialtyOptions } from 'hooks/common/useTherapistFilters/options';
import { TherapistSearchFilters } from 'hooks/common/useTherapistFilters/use-filtered-therapists';
import { SelectOption } from 'types';
import 'twin.macro';

import { findTimezoneOptionByLabel, TimezoneOption, timezoneOptions } from 'hooks/common/useDisplayTimezone/options';

export interface TherapistSearchProps {
  filters: TherapistSearchFilters;
  onChange: (filters: TherapistSearchFilters) => void;
  insuranceOptions: SelectOption[];
  usStatesOptionsWithMultipleTimezones?: SelectOption[];
  autoSelectTimezone: boolean;
  setAutoSelectTimezone: React.Dispatch<React.SetStateAction<boolean>>;
}

const TherapistSearchUi = ({
  filters,
  onChange,
  insuranceOptions,
  usStatesOptionsWithMultipleTimezones,
  autoSelectTimezone,
  setAutoSelectTimezone,
}: TherapistSearchProps) => {
  const { displayTimezone, setDisplayTimezone } = useDisplayTimezone();

  return (
    <>
      <FormGroup tw="w-60">
        <Label htmlFor="States" tw="font-semibold text-sm mb-2">
          State
        </Label>
        <Select
          spacing="tight"
          id="States"
          isSearchable
          options={usStatesOptionsWithMultipleTimezones}
          value={filters.state}
          onChange={(selectedOption: SelectOption & { timezone: string; hasMultipleTimeZone: string }) => {
            onChange({ ...filters, state: selectedOption });

            const matchedTimezoneOption = findTimezoneOptionByLabel(selectedOption.timezone);
            const stateHasMultipleTimezones = selectedOption.hasMultipleTimeZone;

            if (matchedTimezoneOption && !stateHasMultipleTimezones) {
              setAutoSelectTimezone(true);
              setDisplayTimezone(matchedTimezoneOption);
            } else {
              setAutoSelectTimezone(false);
            }
          }}
        />
      </FormGroup>

      <FormGroup tw="flex-grow md:flex-grow-0">
        <Label htmlFor="timezone" tw="font-semibold text-sm mb-2">
          Show Times In
        </Label>
        <div tw="w-full sm:w-full md:w-56">
          <Select
            required
            spacing="tight"
            id="timezone"
            isSearchable
            value={autoSelectTimezone ? displayTimezone : null}
            options={timezoneOptions}
            onChange={(selectedOption: TimezoneOption) => {
              setAutoSelectTimezone(true);
              setDisplayTimezone(selectedOption);
            }}
          />
        </div>
        {!autoSelectTimezone && (
          <p tw="text-xs block leading-tight mt-2 text-gray-600">State may have multiple time zones</p>
        )}
      </FormGroup>

      <FormGroup tw="w-128">
        <Label htmlFor="insurancePlan" tw="font-semibold text-sm mb-2" optional>
          Insurance Plan
        </Label>
        <Select
          spacing="tight"
          id="insurancePlan"
          isSearchable
          isMulti
          isClearable
          options={insuranceOptions}
          value={filters.insurancePlan}
          onChange={(value: SelectOption[]) => {
            onChange({ ...filters, insurancePlan: value });
          }}
        />
      </FormGroup>

      <FormGroup tw="w-128">
        <Label htmlFor="specialty" tw="font-semibold text-sm mb-2" optional>
          Clinical Competencies
        </Label>
        <Select
          isMulti
          spacing="tight"
          id="specialty"
          isSearchable
          options={specialtyOptions}
          value={filters.specialty}
          onChange={(value: SelectOption[]) => {
            onChange({ ...filters, specialty: value });
          }}
        />
      </FormGroup>

      <FormGroup tw="w-48">
        <Label htmlFor="language" tw="font-semibold text-sm mb-2">
          Language
        </Label>
        <Select
          spacing="tight"
          id="language"
          isSearchable
          options={languageOptions}
          value={filters.language}
          onChange={(selectedOption: SelectOption) => {
            onChange({ ...filters, language: selectedOption });
          }}
        />
      </FormGroup>

      <FormGroup tw="flex-shrink-0 flex-row h-[38px] self-end items-center gap-[6px]">
        <Input
          id="acceptingClients"
          type="checkbox"
          name="acceptingClients"
          spacing="tight"
          checked={filters.acceptingClients}
          onChange={event => {
            onChange({ ...filters, acceptingClients: event.target.checked });
          }}
        />
        <Label font="normal" htmlFor="acceptingClients">
          Only show therapists accepting new clients
        </Label>
      </FormGroup>

      <FormGroup tw="flex-shrink-0 flex-row h-[38px] self-end items-center gap-[6px]">
        <Input
          id="isPRN"
          type="checkbox"
          name="isPRN"
          spacing="tight"
          checked={filters.isPRN}
          onChange={event => {
            onChange({ ...filters, isPRN: event.target.checked });
          }}
        />
        <Label font="normal" htmlFor="isPRN">
          Show PRN Therapists
        </Label>
      </FormGroup>
    </>
  );
};

export default TherapistSearchUi;
