import { useMutation, useQuery, useQueryClient } from 'react-query';
import { http } from '@expressable/utils';
import { Contact } from '../types';
import { useToasts } from 'react-toast-notifications';
import { deleteEmptyOrUndefinedFromObject } from 'utils/helpers';
import { presentMutationError } from '@expressable/ui-library';

export interface ClientContactsPayload extends Contact {
  clientID: string;
}

const createClientContact = async (createClientContactPayload: ClientContactsPayload) => {
  let validAddress;
  if (createClientContactPayload.address) {
    validAddress = deleteEmptyOrUndefinedFromObject(createClientContactPayload.address);
    if (Object.values(validAddress).length == 0) {
      delete createClientContactPayload.address;
    } else {
      createClientContactPayload.address = validAddress;
    }
  }
  return http.put(`/contacts`, createClientContactPayload).then(res => res.data);
};

export function useCreateClientContact() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createClientContact, {
    onSuccess: (_, payload) => {
      // Query Invalidations
      addToast('Client Information Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client', payload.clientID]);
    },
    onError: presentMutationError,
  });
}

const editClientContact = async (editClientContactPayload: ClientContactsPayload) => {
  let validAddress;
  if (editClientContactPayload.address) {
    validAddress = deleteEmptyOrUndefinedFromObject(editClientContactPayload.address);
    if (Object.values(validAddress).length == 0) {
      delete editClientContactPayload.address;
    } else {
      editClientContactPayload.address = validAddress;
    }
  } else {
    // The `address` field is null or undefined, so delete it to avoid
    // validation errors
    delete editClientContactPayload.address;
  }
  const { contactID, ...clientContact } = editClientContactPayload;
  return http.post(`/contacts/${contactID}`, clientContact).then(res => res.data);
};

export function useEditClientContact(options: { notifications?: boolean } = {}) {
  const { notifications = true } = options;
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(editClientContact, {
    onSuccess: (_, payload) => {
      if (notifications) {
        addToast('Contact Information Successfully Updated', { appearance: 'success', autoDismiss: true });
      }
      // Query Invalidations
      queryClient.invalidateQueries(['client', payload.clientID]);
    },
    onError: notifications ? presentMutationError : undefined,
  });
}

export interface DeleteClientContactPayload {
  contactID: string;
  clientID: string;
}

const deleteClientContact = async (deleteClientContactPayload: DeleteClientContactPayload) => {
  const { contactID, clientID } = deleteClientContactPayload;
  return http
    .delete(`/contacts/${contactID}`, {
      data: {
        clientID: clientID,
      },
    })
    .then(res => res.data);
};

export function useDeleteClientContact() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(deleteClientContact, {
    onSuccess: (_, payload) => {
      // Query Invalidations
      addToast('Contact Successfully Deleted', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client', payload.clientID]);
    },
    onError: presentMutationError,
  });
}

export interface GrantPortalAccessPayload {
  contactID: string;
  clientID: string;
}

export interface GrantPortalAccessResponse {
  email: string;
  temporaryPassword: string;
  isEmailConfirmed: boolean;
}

export interface ClientGrantedPortalAccess {
  contactID: string;
  contactName: string;
  cognitoUsername: string;
  clientID: string;
  clientName: string;
  isClientActive: boolean;
}

export interface AddDuplicatedContactPayload {
  contactID: string;
  clientID: string;
  relationshipToClient: string;
}

const grantPortalAccess = async ({ contactID, clientID }: GrantPortalAccessPayload) => {
  return http
    .post<GrantPortalAccessResponse>(`/contacts/${contactID}/portal-access`, { clientID })
    .then(res => res.data);
};

export function useGrantPortalAccess() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(grantPortalAccess, {
    onSuccess: (_, payload) => {
      // Query Invalidations
      addToast('Portal Access Successfully Granted', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client', payload.clientID]);
    },
    onError: presentMutationError,
  });
}

const getClientsGrantedPortalAccess = async (email: string) => {
  const { data } = await http.get<ClientGrantedPortalAccess[]>(`/contacts/${email}/portal-access`);
  return data;
};

export function useGetClientsGrantedPortalAccess(email: string) {
  return useQuery(['granted-portal-access', email], () => getClientsGrantedPortalAccess(email));
}

const addDuplicatedContact = async ({ contactID, clientID, relationshipToClient }: AddDuplicatedContactPayload) => {
  return http
    .post<AddDuplicatedContactPayload>(`/clients/${clientID}/contact/${contactID}`, { relationshipToClient })
    .then(res => res.data);
};

export function useAddDuplicatedContact() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(addDuplicatedContact, {
    onSuccess: (_, payload) => {
      // Query Invalidations
      addToast('Client Information Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client', payload.clientID]);
    },
    onError: presentMutationError,
  });
}
