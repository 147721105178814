import TherapistSearchUi, { TherapistSearchProps } from 'components/therapistFilter/ui';
import { INSURANCES_ACCEPTED_ENTRY_ID, useContentfulEntry } from 'hooks/use-ContentfulData';
import { useEffect, useMemo } from 'react';
import { Insurances, SelectOption } from 'types';
import usStatesOptions from 'utils/us-states.json';
import statesWithMultipleTimezones from 'utils/us-states-with-multiple-timezones.json';

type Props = Omit<TherapistSearchProps, 'insuranceOptions'>;

const TherapistSearch = (props: Props) => {
  const [insuranceOptionsEntry, , fetchInsuranceOptions] = useContentfulEntry<Insurances>(INSURANCES_ACCEPTED_ENTRY_ID);

  //@TODO (gian): needs to be revisited. This is a temporary fix to make sure the insurance options are fetched before the component is rendered.
  useEffect(fetchInsuranceOptions, []);


  const usStatesOptionsWithMultipleTimezones = usStatesOptions.map(state => {
    if (statesWithMultipleTimezones.includes(state.value)) {
      return { ...state, hasMultipleTimeZone: true };
    }
    return state;
  });

  const insuranceOptions = useMemo<SelectOption[]>(() => {
    if (insuranceOptionsEntry) {
      return insuranceOptionsEntry.fields?.insurances.map((plan: string) => ({
        value: plan.trim(),
        label: plan.trim(),
      }));
    } else {
      return [];
    }
  }, [insuranceOptionsEntry]);

  return (
    <TherapistSearchUi
      {...props}
      insuranceOptions={insuranceOptions}
      usStatesOptionsWithMultipleTimezones={usStatesOptionsWithMultipleTimezones}
    />
  );
};

export default TherapistSearch;
