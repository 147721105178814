import { StepId, ClientWizardStepsData } from 'components/therapistMatcher/data/useTherapistMatcher';
import dayjs, { Dayjs } from 'dayjs';
import { TherapistSearchFilters } from 'hooks/common/useTherapistFilters/use-filtered-therapists';
import { IBillingInfo } from 'hooks/use-billing-information';
import { CreateClientPayload } from 'hooks/use-client';
import { convertFormFieldsToBillingInfo } from 'pages/client/components/billing-info-form';
import { BillingInformationFormData } from 'pages/new-client-create/BillingInformation';
import { BILLING_LINK_TYPES } from 'pages/new-client-create/BillingInformation/options';
import { isAdult } from 'pages/new-client-create/ClientInformation';
import { IBillingInfoForm } from 'types';
import { getBillingIntakeUrl } from 'utils/billing-intake';
import formatZoomLink from 'utils/zoom-link';

type AppointmentDate = {
  dateTime: Dayjs;
  override: boolean;
};

export const formClientWizardPayload = (data: ClientWizardStepsData): CreateClientPayload => {
  const scheduledSessionsInfo = data[StepId.scheduleSessions];
  const clientInfo = data[StepId.clientInfo];
  const billingInfo = data[StepId.billingInfo];
  const { filters = {} as TherapistSearchFilters } = scheduledSessionsInfo;

  if (!clientInfo.contactID) {
    throw new Error('The contactID field should have been filled');
  }

  const therapist = scheduledSessionsInfo.skipped
    ? scheduledSessionsInfo.therapist!
    : scheduledSessionsInfo.evaluationTime.therapist;

  const initialEvaluation = scheduledSessionsInfo.skipped
    ? undefined
    : {
        dateTime: scheduledSessionsInfo.evaluationTime.timestamp.toISOString(),
        acuityCalendarID: parseInt(scheduledSessionsInfo.evaluationTime.therapist.acuityCalendarID),
        duration: scheduledSessionsInfo.evaluationTime.duration,
        override: scheduledSessionsInfo.evaluationTime.override,
      };

  const evaluationOnly = scheduledSessionsInfo.skipped
    ? null
    : scheduledSessionsInfo.firstRecurringSessionInfo &&
      scheduledSessionsInfo.firstRecurringSessionInfo.recurringOption === 'EvaluationOnly';

  const appointmentsDates =
    scheduledSessionsInfo.skipped || evaluationOnly
      ? null
      : [
          {
            dateTime: scheduledSessionsInfo.firstRecurringSessionInfo.timestamp?.timestamp,
            override: scheduledSessionsInfo.firstRecurringSessionInfo.timestamp?.override,
          } as AppointmentDate,
          {
            dateTime: scheduledSessionsInfo.secondRecurringSessionInfo.timestamp?.timestamp,
            override: scheduledSessionsInfo.secondRecurringSessionInfo.timestamp?.override,
          } as AppointmentDate,
        ].filter(x => !!x.dateTime);

  const appointments = appointmentsDates
    ? {
        dates: appointmentsDates.map(appointment => {
          return { ...appointment, dateTime: appointment.dateTime.toISOString() };
        }),
        acuityCalendarID: scheduledSessionsInfo.skipped
          ? undefined
          : parseInt(scheduledSessionsInfo.firstRecurringSessionInfo.timestamp?.therapist.acuityCalendarID + ''),
        recurring: true,
        recurringForever: true,
        ...(scheduledSessionsInfo.skipped
          ? null
          : {
              notSchedulingReason: scheduledSessionsInfo.secondRecurringSessionInfo.notSchedulingReason,
              notSchedulingDescription: scheduledSessionsInfo.secondRecurringSessionInfo.notSchedulingDescription,
            }),
      }
    : undefined;

  const createClientPayload: CreateClientPayload = {
    payload: {
      client: {
        clientFirstName: clientInfo.clientFirstName,
        clientMiddleName: clientInfo.clientMiddleName ?? '',
        clientLastName: clientInfo.clientLastName,

        suffix: clientInfo.clientNameSuffix,
        preferredFirstName: clientInfo.clientNickname,
        dob: clientInfo.dateOfBirth?.format('YYYY-MM-DD'),
        sex: clientInfo.sex?.value,
        genderIdentity: clientInfo.genderIdentity,

        therapist: {
          therapistEmail: therapist.therapistEmail,
          therapistName: therapist.therapistName,
        },
        isClientMinor: !isAdult(dayjs(clientInfo.dateOfBirth)),

        clinicalCompetencies: filters?.specialty?.map(({ value }) => value) ?? [],
        insurancePlan: filters?.insurancePlan?.map(({ value }) => value) ?? [],
      },
      primaryContact: {
        contactID: clientInfo.contactID,
        contactFirstName: clientInfo.isClientSelf ? clientInfo.clientFirstName : clientInfo.contactName ?? '',
        contactMiddleName: (clientInfo.isClientSelf ? clientInfo.clientMiddleName : clientInfo.contactMiddleName) ?? '',
        contactLastName: clientInfo.isClientSelf ? clientInfo.clientLastName : clientInfo.contactLastName ?? '',
        contactTimeZone: clientInfo.contactTimezone.local,
        relationshipToClient: clientInfo.isClientSelf ? 'Client' : clientInfo.relationshipToClient?.value ?? '',
        address: {
          street: clientInfo.address1,
          line2: clientInfo.address2,
          postalCode: clientInfo.zipCode,
          state: clientInfo.state.value,
          city: clientInfo.city,
        },
        email: clientInfo.email,
        phone: {
          [clientInfo.phoneType.value]: clientInfo.phone,
        },
      },
      sendIntake: {
        zoomLink: formatZoomLink(therapist.therapistName),
        billingIntakeUrl: getBillingIntakeUrl(),
        intake: clientInfo.intakeType.value,
      },
      initialEvaluation,
      appointments,
      billing: processBillingInfo(billingInfo),
    },
  };

  return createClientPayload;
};

const processBillingInfo = (billing?: BillingInformationFormData): IBillingInfo | undefined => {
  if (billing && !billing.skipped) {
    if (billing.billingLinkType?.value === BILLING_LINK_TYPES.DEPOSIT_ONLY) {
      return convertFormFieldsToBillingInfo(
        { ...billing?.insuranceContent, billingType: 'insurance', automaticBillingEnabled: 'yes' } as IBillingInfoForm,
        undefined,
      );
    } else if (billing.billingLinkType?.value === BILLING_LINK_TYPES.EXEMPT_FROM_PATIENT_RESPONSIBILITY) {
      const result = convertFormFieldsToBillingInfo(
        { ...billing?.insuranceContent, billingType: 'insurance', automaticBillingEnabled: 'yes' } as IBillingInfoForm,
        undefined,
      );

      return { ...result, exemptFromPatientResponsibility: true };
    }
  } else {
    return undefined;
  }
};
