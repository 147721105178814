import React from 'react';
import tw from 'twin.macro';
import { Link } from '@expressable/ui-library';
import { UnlockedNoteInfo, BadgeAppointmentNote } from 'types';
import {cond} from "lodash";

const Badge = ({ count }: { count: number }) => {
  const baseBadgeStyle = tw`w-auto px-1 text-center text-xs font-bold inline-block rounded-full`;
  let badgeStyle;

  if (count > 3) {
    badgeStyle = tw` text-red-700 bg-red-100`;
  } else if (count > 1) {
    badgeStyle = tw`text-yellow-700 bg-yellow-100`;
  } else {
    badgeStyle = tw`text-blue-700 bg-blue-100`;
  }

  return <div css={[baseBadgeStyle, badgeStyle]}>{count}</div>;
};

export const BadgeDays = ({ count }: { count: number }) => {
  const baseBadgeStyle = tw`w-auto px-1 text-center text-xs font-bold inline-block rounded-full`;
  let badgeStyle;

  if (count > 3) {
    badgeStyle = tw` text-red-700 bg-red-100`;
  } else if (count > 1) {
    badgeStyle = tw`text-yellow-700 bg-yellow-100`;
  } else {
    badgeStyle = tw`text-blue-700 bg-blue-100`;
  }
  const dayCount = cond([
    [(c: number) => c > 1, (c) => `${c} DAYS`],
    [(c: number) => c === 1, (c) => `${c} DAY`],
    [(c: number) => c === 0,  () => `NEW`],
  ]);
  
  return (
    <div css={[baseBadgeStyle, badgeStyle]}>
      {dayCount(count)}
    </div>
  );
};

const BadgeAppointment = ({ appointment }: { appointment: BadgeAppointmentNote }) => {
  const { clientID, clientFirstName, clientLastName, count } = appointment;

  return (
    <div tw="mb-2">
      <Badge count={count} />
      &nbsp;
      <Link tw="text-indigo-700 text-sm font-semibold" to={{ pathname: `/clients/${clientID}` }}>
        {clientFirstName ? `${clientFirstName} ${clientLastName}` : 'Client'}
      </Link>
    </div>
  );
};

type BadgeAdminNoteProps = {
  appointment: UnlockedNoteInfo;
};

export const UnlockedNoteBadge: React.FC<BadgeAdminNoteProps> = ({ appointment }) => {
  const { clientID, clientFirstName, clientLastName, overdueDays } = appointment;

  return (
    <div tw="mb-2">
      <BadgeDays count={overdueDays} />
      &nbsp;
      <Link tw="text-indigo-700 text-sm font-semibold" to={{ pathname: `/clients/${clientID}` }}>
        {clientFirstName ? `${clientFirstName} ${clientLastName}` : 'Client'}
      </Link>
    </div>
  );
};
export default BadgeAppointment;
