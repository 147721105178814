import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const getLDClientID = () => {
  return process.env.REACT_APP_NODE_ENV !== 'production'
    ? process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID
    : process.env.REACT_APP_PROD_LAUNCH_DARKLY_CLIENT_ID;
};

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: getLDClientID() ?? '',
  });
  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root'),
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
