import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Card, Container, FormGroup, FormInline, Label, Link, Loader, Select } from '@expressable/ui-library';
import { SelectOption } from 'types';
import { useFirehoseEvents } from 'hooks/use-firehose-events';
import { removeClientWordFromClientID } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import usePermissions from 'hooks/use-permissions';
import {
  getClientEventAnalytics,
  getFromDate,
  getTherapistEventAnalytics,
  VIEW_DAYS_SELECT_OPTIONS,
} from '../attendances';
import 'twin.macro';

export interface EventAnalyticsProps {
  title: string;
  subTitle: string;
  eventType: string;
  hasTherapistEvent?: boolean;
  therapistEventTitle?: string;
}

const FirehoseEventAnalytics = (props: EventAnalyticsProps) => {
  const { title, subTitle, eventType, hasTherapistEvent, therapistEventTitle } = props;
  const [date, setDate] = useState({ label: 'Last 30 Days', value: '30' });
  const dateFormatted = dayjs().format('YYYY/MM/DD');
  const fromDate = getFromDate(date.value);
  const addDayToCurrentDay = dayjs(dateFormatted).add(1, 'day');
  const toDate = addDayToCurrentDay.toISOString();
  const {
    data: firehoseEvents,
    isLoading,
    isError,
  } = useFirehoseEvents(fromDate as string, toDate as string, eventType, 'All');
  const { isAdminOrClinicalManager } = usePermissions();

  const clientWithMultipleEvents = getClientEventAnalytics(firehoseEvents);
  const therapistsWithMultipleEvents = getTherapistEventAnalytics(firehoseEvents);

  if (isError) {
    return (
      <Container data-testid="firehose-event-analytics-error" tw="relative pl-0" size="large">
        <Card tw="mb-4 px-8">
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              {isAdminOrClinicalManager
                ? 'There was an error while loading the analytics'
                : 'Only users in Admin Groups can perform this action.'}
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  return (
    <div data-testid="firehose-event-analytics">
      <div>
        <Card tw="relative w-full md:w-2/4">
          <div>
            <FormInline tw="w-full">
              <h1 tw="font-semibold text-2xl ml-3">{title}</h1>
              <Label tw="ml-10 flex items-center" htmlFor="view-days">
                View
              </Label>
              <Select
                tw="min-w-[150px] lg:min-w-[200px] ml-3"
                spacing="tight"
                id="selected-view-days"
                options={VIEW_DAYS_SELECT_OPTIONS}
                value={date}
                onChange={(selectedOption: SelectOption) => {
                  setDate(selectedOption);
                }}
              />
            </FormInline>
            <FormInline tw="w-full">
              <div tw="mt-2">
                <span tw="font-semibold mt-3 text-4xl ml-3">{firehoseEvents?.items.length ?? 0}</span>
                <span tw="font-semibold ml-3">Total</span>
              </div>
            </FormInline>
          </div>
          <div tw="border-b border-gray-300 mt-6 w-full" />
          {/* Client Event Analytics */}
          {isLoading ? (
            <div data-testid="attendances-loading" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <div tw="mt-4">
              <span tw="font-semibold ml-3">{subTitle}</span>
              <div tw="mt-4 overflow-y-auto max-h-80">
                {clientWithMultipleEvents?.length ? (
                  <>
                    {clientWithMultipleEvents.map((event, index) => {
                      return (
                        <div key={index}>
                          <ul tw="ml-3">
                            <li tw="mt-2">
                              <span tw="font-bold text-xs mr-3">{event.count}</span>
                              <Link
                                tw="text-indigo-700 font-semibold text-sm"
                                to={{ pathname: `/clients/${removeClientWordFromClientID(event?.clientID)}` }}
                              >
                                {event.clientFirstName} {event.clientLastName}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div>
                    <span tw="text-sm font-semibold ml-3">No clients to show!</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* Therapist Event Analytics */}
          {hasTherapistEvent && (
            <>
              <div tw="border-b border-gray-300 mt-6 w-full" />
              {isLoading ? (
                <div data-testid="attendances-loading" tw="flex-1 text-center py-20">
                  <Loader type="ring" />
                </div>
              ) : (
                <div tw="mt-4">
                  <span tw="font-semibold ml-3">{therapistEventTitle}</span>
                  <div tw="mt-4 overflow-y-auto max-h-80">
                    {therapistsWithMultipleEvents?.length ? (
                      <>
                        {therapistsWithMultipleEvents.map((event, index) => {
                          return (
                            <div key={index}>
                              <ul tw="ml-3">
                                <li tw="mt-2">
                                  <span tw="font-bold text-xs mr-3">{event.count}</span>
                                  <Link
                                    tw="text-indigo-700 font-semibold text-sm"
                                    to={{ pathname: `/therapists/${event?.therapistEmail}` }}
                                  >
                                    {event.therapistFirstName} {event.therapistLastName}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div>
                        <span tw="text-sm font-semibold ml-3">No Therapists to show!</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default FirehoseEventAnalytics;
