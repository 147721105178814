export const CLIENT_DIAGNOSES_ERROR_CODES = [
  'DIAGNOSES_NOT_FOUND',
  'DUPLICATE_DIAGNOSES',
  'ICD_EXCLUSION',
];

export const DEFAULT_AUTODISMISS_TIMEOUT = 5000;

export const ERROR_KEYWORDS_WITH_MESSAGE = [
  'CLIENT_DIAGNOSES_ERROR',
  'INSURANCE_CLAIM_SUBMISSION_ERROR',
  'INVALID_INSURANCE_AUTHORIZATION',
];

export const INSURANCE_ERROR_AUTODISMISS_TIMEOUT = 10000;

export const INSURANCE_ERROR_CODES = [
  'CANDID_HEALTH_VALIDATION_ERROR',
  'CANDID_DUPLICATE_ENCOUNTER_EXCEPTION',
  'DUPLICATE_DIAGNOSIS',
  'INVALID_INSURANCE_AUTHORIZATION',
  'PAYER_NON_COVERED_BENEFIT',
];

export const LOCK_ERROR_CODES_MESSSAGES = {
  CLIENT_NOT_FOUND: 'Client not found',
  PRIMARY_CONTACT_NOT_FOUND: 'Primary contact not found',
  PRIMARY_CONTACT_ADDRESS_NOT_FOUND: 'Primary contact address not found',
  CONTRACTED_BUSINESS_NOT_FOUND: 'Contracted business not found',
  CLIENT_DIAGNOSES_NOT_FOUND: 'Client diagnoses not found',
  AMOUNT_NOT_DEFINED_FOR_CPT: 'Amount not defined for CPT',
  PRIMARY_CONTACT_POSTAL_CODE_NOT_FOUND: 'Primary contact postal code not found',
  INVALID_VALUE: 'Invalid value(s)',
};
