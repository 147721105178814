import {
  Button,
  Container,
  Tab,
  TabList,
  Link,
  TabPanel,
  TabPanels,
  Tabs,
  TitleBar,
  Badge,
  useDisclosure,
  Loader,
  LoadingText,
} from '@expressable/ui-library';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useClient } from 'hooks/use-client';
import ClientOverview from './components/client-overview';
import EditClientInfoForm from './components/edit-client-info-form';
import ClientTeamForm from './components/client-team-form';
import ClientContactsForm from './components/client-contacts-form';
import BillingInfoForm from './components/billing-info-form';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useEditClientStatus from 'hooks/use-clients-status';
import { ArchiveClientModal } from './components/archive-client-modal';
import { Helmet } from 'react-helmet';
import getInitials from 'utils/name-initials';
import ClientPlanOfCareForm from './components/client-plan-of-care-form';
import ClientResources from './components/client-resources/client-resources';
import { ClientPortal } from './components/client-portal';
import CommunicationPreferences from './components/communication-preferences';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatAge, getAmericanFormatDate } from 'utils/helpers';
import { ClientManageAssignments } from './components/client-manage-assignments';
import PendingReschedules from 'pages/admin/components/pending-reschedules';
import { useGetClientCommunicationPreferences } from 'hooks/use-communication-preferences';
import { ClientMatchingPage } from './components/client-matching';

export enum ClientTab {
  Overview = 0,
  ClientInfo = 1,
  Contacts = 2,
  PlanOfCare = 3,
  PendingReschedules = 4,
  CCEContentLibrary = 5,
  BillingInfo = 6,
  ClientPortal = 7,
  ManageAssignments = 8,
  CommunicationPreferences = 9,
  Matching = 10,
}

type TParams = { clientId: string };

const ArchiveClientLink = ({ clientId }: { clientId: string }) => {
  const {
    isOpen: ArchiveClientModalIsOpen,
    onClose: ArchiveClientModalOnClose,
    onOpen: ArchiveClientModalOnOpen,
  } = useDisclosure();

  let LinkComponent;
  const [inProgress, setInProgress] = useState(false);
  const { data: clientData } = useClient(clientId);
  const status = clientData?.status ?? '';
  const { mutateAsync: editClientStatus } = useEditClientStatus();
  const { archiveClientLink: isArchiveClientLinkEnabled } = useFlags();
  // show loader when Unarchive Client is clicked
  if (inProgress) {
    return (
      <div tw="pt-10 pb-10">
        <LoadingText />
      </div>
    );
  }

  if (status == 'Active') {
    if (!isArchiveClientLinkEnabled) {
      return null;
    }

    LinkComponent = (
      <div tw="pt-10 pb-10">
        {ArchiveClientModalIsOpen && (
          <ArchiveClientModal
            isOpen={ArchiveClientModalIsOpen}
            onClose={ArchiveClientModalOnClose}
            clientId={clientId}
          />
        )}
        <Link
          data-testid="archive-client"
          onClick={ArchiveClientModalOnOpen}
          tw="contents py-10 text-red-700 font-medium"
          to="#"
        >
          <FontAwesomeIcon tw="text-base mr-2" icon={['far', 'trash']} />
          Archive this Client
        </Link>
      </div>
    );
  } else {
    LinkComponent = (
      <div tw="pt-10 pb-10">
        <Link
          onClick={() => {
            if (!inProgress) {
              editClientStatus({ clientId, status: 'Active' })
                .then(() => {
                  setInProgress(false);
                })
                .catch(err => {
                  console.error(err);
                });
            }

            setInProgress(true);
          }}
          data-testid="unarchive-client"
          tw="contents py-10"
          to="#"
        >
          <FontAwesomeIcon tw="text-base mr-2" icon={['fas', 'trash-undo-alt']} />
          Unarchive this Client
        </Link>
      </div>
    );
  }
  return LinkComponent;
};

const ArchivedBadge = ({ status }: { status: 'Active' | 'Inactive' }) =>
  status == 'Inactive' ? <Badge variant="disabled">Archived</Badge> : null;

const Clients = (props: RouteComponentProps<TParams>) => {
  const {
    params: { clientId },
  } = props.match;
  const { hash } = props.location;
  const { data: clientData, isLoading } = useClient(clientId);
  const { data: clientCommunicationPreferences } = useGetClientCommunicationPreferences(clientId);
  const history = useHistory();
  const {
    communicationPreferencesTab: isCommunicationPreferencesEnabled,
    clientMatching: clientMatchingEnabled,
    clinicalPathways: isClinicalPathwaysEnabled,
  } = useFlags();
  const age = formatAge(clientData?.dob);
  const americanFormatDob = getAmericanFormatDate(clientData?.dob);

  const tabHashes = [
    '#overview',
    '#client-info',
    '#contacts',
    '#plan-of-care',
    '#pending-reschedules',
    '#cce-content-library',
    '#billing-info',
    '#client-portal',
    '#client-manage-assignments',
    '#communication-preferences',
    '#matching',
  ];

  const tabindex = tabHashes.indexOf(hash);
  const [index, setIndex] = useState<ClientTab>(tabindex < 0 ? ClientTab.Overview : tabindex);

  // Handle changes to the #hash
  const handleHashchange = useCallback(() => {
    const newTabindex = Math.max(tabHashes.indexOf(window.location.hash));
    if (newTabindex !== index) {
      setIndex(newTabindex);
    }
  }, [index, setIndex]);

  useEffect(() => {
    window.addEventListener('hashchange', handleHashchange);
    return () => window.removeEventListener('hashchange', handleHashchange);
  }, [handleHashchange]);

  useEffect(() => {
    if (tabindex === -1) {
      setIndex(ClientTab.Overview);
    } else {
      setIndex(tabindex);
    }
  }, [isClinicalPathwaysEnabled, index]);

  // if there is no hash, redirect to the first tab
  useEffect(() => {
    if (!hash) {
      history.push(`/clients/${clientId}${tabHashes[ClientTab.Overview]}`);
    }
  }, [hash, history, clientId]);

  const clientInitials = getInitials(
    `${clientData?.clientFirstName ?? ''}
    ${clientData?.clientLastName ?? ''}`,
  );

  if (isLoading) {
    return (
      <div data-testid="client" tw="h-screen flex justify-center items-center">
        <Loader type="ring" />
      </div>
    );
  }

  return (
    <div data-testid="client" css={{ marginBottom: 28 }}>
      {clientData && (
        <>
          <Helmet title={`${clientInitials}'s Client Profile`} />
          <div className="shadow bg-white">
            <TitleBar
              title={`${clientData.clientFirstName} ${clientData.clientLastName}`}
              action={<ArchivedBadge status={clientData.status} />}
              className="border-b-0 shadow-none -mb-4"
            />
            {clientData.dob && (
              <div className="pb-6">
                <div className="ml-4 sm:ml-6 md:ml-6 lg:ml-8 font-normal text-gray-500 text-sm">
                  <span>{`DOB: ${americanFormatDob} (${age} old) `}</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {clientData && (
        <Container size="large">
          <Tabs
            index={getPatchedTabIndex(index)}
            onChange={index => {
              setIndex(index);
              history.push(`/clients/${clientId}${tabHashes[index]}`);
            }}
            defaultIndex={getPatchedTabIndex(tabindex)}
          >
            <TabList tw="mt-4 mb-10">
              <Tab data-testid="overview" id="overview">
                Overview
              </Tab>
              <Tab data-testid="clientInfo" id="client-info">
                Client Info
              </Tab>
              <Tab data-testid="contacts" id="contacts">
                Contacts
              </Tab>
              <Tab data-testid="planOfCare" id="plan-of-care">
                Plan of Care
              </Tab>
              <Tab data-testid="pendingReschedules" id="pending-reschedules">
                Pending Reschedules
              </Tab>
              <Tab data-testid="clientResources" id="cce-content-library">
                CCE Content Library
              </Tab>
              <Tab data-testid="billingInfo" id="billing-info">
                Billing Info
              </Tab>
              <Tab data-testid="clientPortal" id="client-portal">
                Client Portal
              </Tab>
              <Tab data-testid="clientManageAssignments" id="client-manage-assignments">
                Manage Assignments
              </Tab>
              <Tab data-testid="communicationPreferences" id="communication-preferences">
                Communication Preferences
              </Tab>
              {clientMatchingEnabled && (
                <Tab data-testid="matching" id="matching">
                  Matching
                </Tab>
              )}
            </TabList>
            <TabPanels>
              <TabPanel>
                <ClientOverview setIndex={setIndex} clientId={clientId} />
              </TabPanel>
              <TabPanel>
                <EditClientInfoForm clientId={clientId} />
                <ClientTeamForm clientId={clientId} />
                <ArchiveClientLink clientId={clientId} />
              </TabPanel>
              <TabPanel>
                <ClientContactsForm clientId={clientId} />
              </TabPanel>
              <TabPanel>
                <ClientPlanOfCareForm clientId={clientId} />
              </TabPanel>
              <TabPanel>
                <PendingReschedules
                  isAdminPage={false}
                  isClientPage={true}
                  isTherapistPage={false}
                  clientId={clientId}
                />
              </TabPanel>
              <TabPanel>
                <ClientResources />
              </TabPanel>
              <TabPanel>
                <BillingInfoForm clientId={clientId} clientData={clientData} />
              </TabPanel>
              <TabPanel>
                <ClientPortal clientId={clientId} clientData={clientData} />
              </TabPanel>
              <TabPanel>
                {isClinicalPathwaysEnabled ? (
                  <ClientManageAssignments clientId={clientId} clientData={clientData} />
                ) : (
                  <div>
                    Sorry, you don’t have access to this page. Please reach out to the help center if you need access.
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {clientCommunicationPreferences && (
                  <>
                    {isCommunicationPreferencesEnabled ? (
                      <CommunicationPreferences
                        clientID={clientId}
                        clientCommunicationPreferences={clientCommunicationPreferences}
                      />
                    ) : (
                      <div>
                        Sorry, you don’t have access to this page. Please reach out to the help center if you need
                        access.
                      </div>
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel>
                {!clientMatchingEnabled && (
                  <div>
                    Sorry, you don’t have access to this page. Please reach out to the help center if you need access.
                  </div>
                )}
                {clientMatchingEnabled && <ClientMatchingPage clientId={clientId} setTab={setIndex} />}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      )}
      {!clientData && !isLoading && (
        <div tw="relative">
          <TitleBar
            title="Clients"
            action={
              <Link tw="text-indigo-700" to="/client/create">
                <FontAwesomeIcon tw="text-2xl align-middle mr-3" icon={['far', 'plus']} />
                <span tw="align-middle font-bold">Create New Client</span>
              </Link>
            }
          />
          <Container size="large" tw="pt-12">
            <div tw="bg-gray-200 m-auto p-28">
              <div tw="text-center">
                <p tw="text-3xl text-gray-400 mb-4">We cannot find a client with that ID</p>
                <Button variant="secondary" onClick={() => props.history.push(`/clients`)}>
                  Back to Clients
                </Button>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

/** A patch to prevent passing invalid tab indexes to the tabList component which makes it crash */
const getPatchedTabIndex = (index: number) => {
  if (index < 0) {
    return 0;
  }
  return index;
};

export default Clients;
