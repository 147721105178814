import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faUserDoctor } from '@fortawesome/pro-solid-svg-icons';
import { EventProps } from 'domain/admin/types';
import { removeClientWordFromClientID } from 'utils/helpers';
import 'twin.macro';

const ProviderAdded = (event: EventProps) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-provider-added" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faUserDoctor} tw="mx-1.5 h-3.5 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1 text-indigo-700">
            <Link to={{ pathname: `/contacts/${removeClientWordFromClientID(firehoseEvent?.primaryContactID)}` }}>
              {firehoseEvent?.primaryContactFirstName} {firehoseEvent?.primaryContactLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`added ${firehoseEvent.providerFullName} ${
              firehoseEvent?.clientFirstName && 'to'
            }`}</span>
            &nbsp;
            {firehoseEvent?.clientFirstName && (
              <Link
                tw="text-indigo-700"
                to={{ pathname: `/clients/${removeClientWordFromClientID(firehoseEvent?.clientID)}` }}
              >
                {`${firehoseEvent?.clientFirstName} ${firehoseEvent?.clientLastName}`}
              </Link>
            )}
            {firehoseEvent?.clientFirstName && <span tw="text-gray-500">&apos;s care team</span>}
          </div>
          {firehoseEvent?.detail?.location?.clinicName && (
            <div>
              <span>{`Clinic: ${firehoseEvent.detail.location.clinicName}`} </span>
            </div>
          )}
          {firehoseEvent?.detail?.location?.address && (
            <div>
              <span>{`Address: ${firehoseEvent.detail.location.address}`} </span>
            </div>
          )}
          {firehoseEvent?.detail?.phone && (
            <div>
              <span>{`Phone: ${firehoseEvent.detail.phone}`} </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProviderAdded;
