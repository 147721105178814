import { Address, Contact, SelectOption, Therapist, TherapistTimesheetZeroHours } from 'types';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import React from 'react';
import { Goal } from 'hooks/use-care-plan-goals';
import { onlyLettersSpecialVowelsWhiteSpacesPattern } from 'pages/new-client-create/ClientInformation';
import { TherapistPayroll } from 'hooks/use-therapist-payroll';
import { isEmpty } from 'lodash';
import { TimezoneOption } from 'hooks/common/useDisplayTimezone/options';
import { ClientContactsPayload } from 'hooks/use-client-contacts';

/**
 * Delete empty strings or undefined from an given object
 */

type AddressKeys = keyof Address;
export function deleteEmptyOrUndefinedFromObject(object: Address) {
  return Object.keys(object).reduce((acu, current) => {
    if (typeof object[current as AddressKeys] != 'undefined' && object[current as AddressKeys]?.length) {
      acu = Object.assign(acu, { [current]: object[current as AddressKeys] });
    }
    return acu;
  }, {});
}

export const phoneInputHandle = (value: string) => {
  let newValue = new AsYouType('US').input(value);
  if (value.includes('(') && newValue[newValue.length - 1] === ')' && value[value.length - 1] !== ')') {
    newValue = newValue.slice(0, -1);
  }
  const validNumber = parsePhoneNumber(newValue, 'US');
  const flag = validNumber && validNumber.isValid();
  return {
    flag,
    newValue,
  };
};

export const validateZipcode = (val: string | undefined) => {
  const validator = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return !val || validator.test(val) || 'Only 99999 or 99999-9999 formats are allowed.';
};

export const alphabeticOnlyRegex = new RegExp("^[A-Za-z]+([ /'.-]*[A-Za-z]*)*$");

export const onlyLettersSpecialVowelsWhiteSpacesValidator = (value: string | undefined) =>
  !value || onlyLettersSpecialVowelsWhiteSpacesPattern.test(value) || 'Invalid input.';

const alphabeticAndParenthesesRegex = new RegExp('^[a-zA-Zs/() ]+$');

export const alphabeticAndParenthesesValidator = (value: string | undefined) =>
  !value || alphabeticAndParenthesesRegex.test(value) || 'Invalid input.';

export const emptyValidator = (value: string) => !!value.trim() || 'Please fill out this field.';

export function truncateZeroes(str: string): string {
  if (isNaN(parseInt(str))) return '';
  if (parseInt(str) === 0) return '0';

  return str?.replace(/^(-)?0+(0\.|\d)/, '$1$2');
}

export function capitalizeFirst(str?: string): string {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Not been used currently but will be kept since it probably should be useful soon
export function createRandomUUID(): string {
  if (window.crypto) {
    const uint32 = window?.crypto?.getRandomValues(new Uint32Array(1))[0];
    return uint32?.toString(16);
  }

  // fallback if crypto is not available in the browser
  return new Date().getTime().toString();
}

export function getYearsFromNow(date: string): number | undefined {
  const years = Math.abs(dayjs().diff(dayjs(date), 'year'));
  return isNaN(years) ? undefined : years;
}

export function getAmericanFormatDate(date: string | undefined) {
  const americanFormatDate = date?.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$2/$3/$1');
  return americanFormatDate;
}

export function formatAge(dob: Date | Dayjs | string | null | undefined) {
  if (!dob) {
    return null;
  }
  const dayjsDob = dayjs(dob);

  const now = dayjs();
  const years = now.diff(dayjsDob, 'year');
  const months = now.diff(dayjsDob, 'months');
  const monthsRemainder = months % 12;

  const pieces = [];
  if (years) {
    pieces.push(years, ' year');
    if (years > 1) {
      pieces.push('s');
    }
  }

  if (monthsRemainder) {
    if (pieces.length > 0) {
      pieces.push(' ');
    }
    pieces.push(monthsRemainder, ' month');
    if (monthsRemainder > 1) {
      pieces.push('s');
    }
  }

  return pieces.join('');
}

export function isClientOnRange(date: string, range: string) {
  const [start, end] = range.split('-');
  const startDate = dayjs(date);

  // get how old is the client in years
  const age = Math.abs(startDate.diff(dayjs(), 'year'));

  // // case for 14-17 range
  if (range === '14+') return age >= 14 && age <= 17;

  // // case for adults
  if (range === 'Adult') return age >= 18;

  if (!start || !end) return false;

  // if the client is on the range
  return age >= parseInt(start) && age <= parseInt(end);
}

export function isValidUrl(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(str);
}

export const roundNumberByDecimal = (number: number) => Math.ceil(number * 10) / 10;

export function removeClientWordFromClientID(str: string): string {
  const pattern = new RegExp('^[^_]*_');
  return str?.replace(pattern, '');
}

export function removeHyphenFromStringChain(str: string) {
  const pattern = /-/g;
  return str?.replace(pattern, ' ');
}

export function isValidStripeId(value: string) {
  const isValidStripeIDRegex = /^cus_[a-zA-Z0-9.-]{14}$/;
  const regexTester = new RegExp(isValidStripeIDRegex);

  return regexTester.test(value) || !value ? true : 'Invalid Stripe Customer ID';
}

export const formatAppointmentCalendarName = (calendarName: string) => {
  if (!calendarName.includes('.')) {
    return calendarName;
  }
  return calendarName
    .split('.')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
    .join(' ');
};

export function isDateRangeInvalid(startDate: string, endDate: string) {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const isStartDateGreaterThanEndDate = startDateMoment.isAfter(endDateMoment);
  const isStartDateEqualToEndDate = startDateMoment.isSame(endDateMoment);

  return isStartDateGreaterThanEndDate || isStartDateEqualToEndDate;
}

export function filterGoalsByStatus(allGoals?: Goal[], status?: string) {
  if (!allGoals || !status) return [];
  switch (true) {
    case status === 'all':
      return allGoals;
    case status === 'active':
      return allGoals?.filter(goal => goal.status === 'progressing' || goal.status === 'modified');

    // since we're using the this method for filtering STG as well, we can add a custom case where its only applied only to an array of stg
    case Boolean(status === 'discontinued' || status === 'met') && allGoals?.every(goal => goal.goalType === 'stg'):
      return allGoals;

    default:
      return allGoals?.filter(goal => goal.status === status);
  }
}

export function textWithLineBreaks(text?: string | null) {
  if (!text) {
    return null;
  }

  const textParts = text.split(/\r?\n/);
  const textElements = textParts
    .slice(0, -1)
    .map((t, tIndex) => [t, <br key={tIndex} />])
    .flat();
  return [...textElements, textParts[textParts.length - 1]].filter(Boolean);
}

export function generateReportingWeeks(
  therapistTimesheet: TherapistPayroll[],
): SelectOption<{ start: string; end: string; currentWeek?: boolean }>[] {
  const reportingWeeks: SelectOption<{ start: string; end: string; currentWeek?: boolean }>[] = [];

  therapistTimesheet.forEach(timesheet => {
    const startOfWeek: Dayjs = dayjs(timesheet.beginning).startOf('week').startOf('day');
    const endOfWeek: Dayjs = startOfWeek.add(6, 'day').endOf('day');

    // Format the reporting week's label
    const label = `${startOfWeek.format('MM/DD/YYYY')} - ${endOfWeek.format('MM/DD/YYYY')}`;

    // Add the reporting week to the array
    reportingWeeks.push({
      label,
      value: { start: startOfWeek.format('YYYY-MM-DD'), end: endOfWeek.format('YYYY-MM-DD') },
    });
  });

  const currentDate = dayjs();

  // Adds the current week to the array if it is not already in the array
  if (
    !reportingWeeks.find(
      reportingWeek =>
        dayjs(reportingWeek.value.start).isSameOrBefore(currentDate) &&
        dayjs(reportingWeek.value.end).isSameOrAfter(currentDate),
    )
  ) {
    const startOfWeek: Dayjs = currentDate.startOf('week').startOf('day');
    const endOfWeek: Dayjs = startOfWeek.add(6, 'day').endOf('day');

    // Format the reporting week's label
    const label = `${startOfWeek.format('MM/DD/YYYY')} - ${endOfWeek.format('MM/DD/YYYY')}`;
    // Add the reporting week as the first item in the array
    reportingWeeks.unshift({
      label,
      value: { start: startOfWeek.format('YYYY-MM-DD'), end: endOfWeek.format('YYYY-MM-DD'), currentWeek: true },
    });
  }

  // Sort reporting weeks by start date desc
  reportingWeeks.sort((a, b) => b.value.start.localeCompare(a.value.start));

  return reportingWeeks;
}

/**
 * Handler for the `onInput` event to force uppercase characters in `<input>` elements.
 *
 * @param event Event from `onInput`
 */
export function forceUpperCase(event: React.FormEvent<HTMLInputElement>) {
  const input = event.target as HTMLInputElement;
  const start = input.selectionStart;
  const end = input.selectionEnd;
  input.value = input.value.toUpperCase();
  setTimeout(() => input.setSelectionRange(start, end), 0);
}

export const isOtherReasonValid = (reason: string, otherReason?: string) => {
  if (reason.toLowerCase() === 'other') {
    return isEmpty(otherReason);
  }
  return false;
};

type CurrencyFormatOptions = {
  prefix?: string;
  decimals?: number;
};

const defaulCurrencytOptions = { prefix: '$', decimals: 2 };

export const formatCurrencyDollars = (
  amount: number,
  options: CurrencyFormatOptions = defaulCurrencytOptions,
): string => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};

/**
 * @desc returns a date string in YYYY-MM-DD format
 * with the next occurrence of the specified day of the week
 *
 * @param day - day of the week (e.g. 'monday')
 * @returns {string} - date string in YYYY-MM-DD format
 * */
export const getDateByWeekDay = (day: string, isRecurring: boolean) => {
  // Validate the input day
  const validDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  day = day.toLowerCase();

  if (!validDays.includes(day)) {
    throw new Error('Invalid day of the week');
  }

  const currentDate = moment();
  const currentDayOfWeek = currentDate.format('dddd').toLowerCase();

  // if currentDayofWeek is equal to today´s day return same day
  if (isRecurring) {
    if (day === currentDayOfWeek) {
      return currentDate.format('YYYY-MM-DD');
    }
  }

  // Find the next occurrence of the specified day
  const nextDate = currentDate.clone().startOf('week');
  while (nextDate.format('dddd').toLowerCase() !== day) {
    nextDate.add(1, 'day');
  }

  // check if date is in the past
  if (nextDate.isBefore(currentDate)) {
    nextDate.add(1, 'week');
  }
  return nextDate.format('YYYY-MM-DD');
};

export const convertToSingularLowerCaseDay = (day: string) => {
  return day.toLowerCase().replace(/s$/, '');
};

export const moveToNextDay = (date: string, dayOfTheWeek: string, time: string): string => {
  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  // Parse the input date using Day.js
  const currentDate = dayjs(date);

  // Find the current day of the week (0-6, where 0 is Sunday)
  const currentDayOfWeek = currentDate.day();

  // Find the index of the target day of the week
  const targetDayIndex = daysOfWeek.indexOf(dayOfTheWeek.toLowerCase());

  if (targetDayIndex === -1) {
    throw new Error('Invalid day of the week');
  }

  // Calculate the number of days to add to reach the target day
  const daysToAdd = (targetDayIndex + 7 - currentDayOfWeek) % 7;

  // Parse the input time string, assuming it's in "hh:mm a" format
  const timeComponents = time.match(/(\d+):(\d+) ([APap][Mm])/);

  if (!timeComponents) {
    throw new Error('Invalid time format');
  }

  const hours = parseInt(timeComponents[1]);
  const minutes = parseInt(timeComponents[2]);
  const isPM = timeComponents[3].toLowerCase() === 'pm';

  // Update the date with the calculated days and time
  const updatedDate = currentDate
    .add(daysToAdd, 'day')
    .hour(isPM && hours !== 12 ? hours + 12 : hours)
    .minute(minutes);

  return updatedDate.toISOString();
};

export function buildAddressString(address: Address | undefined): string | undefined {
  const addressParts: string[] = [];
  if (!address) return undefined;

  if (address.city) {
    addressParts.push(address.city);
  }

  if (address.street) {
    addressParts.push(address.street);
  }

  if (address.line2) {
    addressParts.push(address.line2);
  }

  if (address.postalCode) {
    addressParts.push(address.postalCode);
  }

  if (address.state) {
    addressParts.push(address.state);
  }

  if (addressParts.length === 0) {
    return undefined; // Return undefined if the address object is empty
  }

  return addressParts.join(', ');
}

export function convertOptionTimeToDayJsDate(date: Dayjs, optionTimeSelected: SelectOption, timezone: TimezoneOption) {
  const [hour, rest] = optionTimeSelected.label.split(':');
  const [minutes, meridiem] = rest.split(' ');
  const twelveHour = 12;

  const currentDateTimeInSelectedTimeZone = dayjs.tz(date, timezone.value);

  let newDate = currentDateTimeInSelectedTimeZone.hour(Number(hour)).minute(Number(minutes));

  if (meridiem === 'pm' && newDate.hour() !== twelveHour) {
    newDate = newDate.add(12, 'hours');
  }

  return newDate;
}

export function isCurrentUserActiveTherapist(therapistsData: Therapist[] | undefined, currentUser: { email: string }) {
  const currentUserIsActiveTherapist = therapistsData?.some((therapist: Therapist) => {
    return therapist.therapistEmail === currentUser?.email;
  });

  return currentUserIsActiveTherapist;
}

type StringTuple = [string, string | undefined];

export function splitNumberByDecimal(number: number): StringTuple {
  if (typeof number !== 'number') return ['', undefined];

  // Convert the number to a string and split it by the decimal point.
  const parts = number.toString().split('.');

  // Return the parts as an array.
  // If the number is an integer (no decimal point), parts[1] will be undefined.
  return parts as StringTuple;
}

export function formatHoursAndMinutes(hours: string, minutesInHours?: string) {
  // Validate if hours and minutesInHours are truthy
  if (hours && minutesInHours) {
    let minutes = Number(`0.${minutesInHours}`) * 60; // `minutesInHours` is a fraction of an hour in decimal number
    if (!Number.isInteger(minutes)) {
      minutes = Math.round(minutes);
    }
    return `${hours}hr ${minutes}min`;
  } else if (hours && hours !== '0') {
    return `${hours}hr`;
  } else {
    // If neither hours nor minutes are truthy, return ""
    return '';
  }
}

export function getHoursRange(hoursLow: number, hoursHigh: number) {
  const lowRange = formatHoursAndMinutes(...splitNumberByDecimal(hoursLow));
  const highRange = formatHoursAndMinutes(...splitNumberByDecimal(hoursHigh));

  return Boolean(lowRange) && Boolean(highRange) ? `${lowRange} - ${highRange}` : TherapistTimesheetZeroHours;
}

export const getDateFromDayOfWeekAndTime = (dayOfWeek: number, time: string, timezone: string) => {
  const now = dayjs().tz(timezone);

  const candidate = now.day(dayOfWeek);

  const date = candidate.isSameOrAfter(now) ? candidate : candidate.add(7, 'day');

  const dateTime = date
    .hour(Number(time.split(':')[0]))
    .minute(Number(time.split(':')[1]))
    .second(0)
    .millisecond(0)
    .utc();

  return {
    dayOfWeek: dateTime.day(),
    time: dateTime.format('HH:mm'),
  };
};

export const removeNonNumericCharacters = (phoneNumber: string) => {
  return phoneNumber?.replace(/\D/g, '');
};

export const validateGuaranteedHoursNumber = (value: number) => {
  if (value.toString().includes('.') && !value.toString().endsWith('.5')) {
    return 'The number must finish in .5';
  } else {
    if (value < 0 || value > 60) {
      return 'The number must be beetween 0 and 60';
    } else {
      return true;
    }
  }
};

export const getPhoneNumber = (phone: ClientContactsPayload['phone']): string | undefined =>
  phone?.mobile?.trim() || phone?.home?.trim() || phone?.work?.trim();

export const getPhoneType = (phoneType: Contact) => {
  return phoneType.mobilePhone ? 'Mobile' : phoneType.homePhone ? 'Home' : 'Work';
};

export const formatCoPay = (value: number) => {
  const strValue = value.toString();

  if (strValue.length <= 3) {
    return `$${strValue}`;
  }

  const integerPart = strValue.slice(0, -2);
  const decimalPart = strValue.slice(-2);
  return `$${integerPart}.${decimalPart}`;
};
