import React, { useRef, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Select,
  Menu,
  MenuLink,
  MenuList,
  MenuItem,
  LoadingText,
} from '@expressable/ui-library';

import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { timeOptions as appointmentTimeOptions, getSelectedTimeDayJs } from 'utils/time-options';
import moment, { Moment } from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import 'twin.macro';
import { useCreateAppointment, CreateAppointmentPayload } from 'hooks/use-appointments';
import { useQueryClient } from 'react-query';
import useTherapists, { useTherapist } from 'hooks/use-therapists';
import { CreateEvaluationPayload, useCreateEvaluation } from 'hooks/use-evaluations';
import { ICareTeam, SelectOption } from 'types';
import { useToasts } from 'react-toast-notifications';
import { useTherapistAvailableTimesV2 } from 'components/therapistMatcher/data';
import {
  AppointmentType,
  appointmentOptions,
  appointmentTypeOptions,
  initialEvaluationOptions,
} from 'domain/appointment/constants';
import { uniqBy } from 'lodash';
import { RECURRING_FOREVER } from 'domain/recurring-appointments/constants';
import { getCueError } from 'utils/error';
import { getWeekSummaryStatusDescription } from 'domain/therapist/constants';
import dayjs from 'dayjs';
import Conflicts from './conflicts';
import { IConflict } from 'utils/appointments/conflicts';

export const displayTimezoneOptions = [
  {
    label: 'Eastern',
    value: 'America/New_York',
    local: 'Eastern',
  },
  {
    label: 'Central',
    value: 'America/Chicago',
    local: 'Central',
  },
  {
    label: 'Mountain',
    value: 'America/Denver',
    local: 'Mountain',
  },
  {
    label: 'Pacific',
    value: 'America/Los_Angeles',
    local: 'Pacific',
  },
  {
    label: 'Mountain (AZ)',
    value: 'America/Phoenix',
    local: 'Mountain (Arizona / No DST Observed)',
  },
  {
    label: 'Hawaiian',
    value: 'Pacific/Honolulu',
    local: 'Hawaiian',
  },
  {
    label: 'Alaskan',
    value: 'America/Anchorage',
    local: 'Alaskan',
  },
];

const recurrenceTimes = [
  { label: RECURRING_FOREVER, value: RECURRING_FOREVER },
  { label: '1 Time', value: '1' },
  ...Array.from({ length: 29 }, (_, index) => ({
    label: `${index + 2} Times`,
    value: `${index + 2}`,
  })),
];

export type AppointmentOption = {
  label: string;
  value: number;
};

export interface AddAppointmentsModalProps {
  clientFirstName: string;
  preferredFirstName?: string;
  clientLastName: string;
  contactEmail?: string;
  contactPhone?: string;
  contactState?: string;
  contactTimeZone?: string;
  careTeam: ICareTeam;
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
}

export const APPOINTMENT_TIME_10_AM = appointmentTimeOptions[20];

export function AddAppointmentsModal(props: AddAppointmentsModalProps) {
  const {
    isOpen,
    onClose,
    clientId,
    clientFirstName,
    preferredFirstName,
    clientLastName,
    contactTimeZone,
    contactEmail,
    contactState,
    contactPhone,
    careTeam,
  } = props;
  const queryClient = useQueryClient();

  const { data: therapistData } = useTherapist(careTeam.primary.therapistEmail);
  const { data: therapists } = useTherapists('active');

  const [selectedTherapist, setSelectedTherapist] = useState<SelectOption>({
    label: `${therapistData?.firstName} ${therapistData?.lastName}`,
    value: therapistData?.therapistID,
  });

  const createEvaluationMutation = useCreateEvaluation();
  const createAppointmentMutation = useCreateAppointment();

  const { mutate: createAppointment } = createAppointmentMutation;
  const { mutate: createEvaluation } = createEvaluationMutation;

  const [date, setDate] = useState<Moment | null>(moment());
  const [time, setTime] = useState<{ label: string; value: number; nonTzValue: number }>(APPOINTMENT_TIME_10_AM);
  const [recurringTimes, setRecurringTimes] = useState(recurrenceTimes[0]);
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [displayTimezone, setDisplayTimezone] = useState<Record<string, string>>(displayTimezoneOptions[0]);
  const [availableTimes, setAvailableTimes] = useState(true);
  const [isGuessTimezoneReady, setIsGuessTimezoneReady] = useState<boolean>(false);

  const [appointmentType, setAppointmentType] = useState<{ [key: string]: string }>(appointmentTypeOptions[0]);
  const [appointmentLength, setAppointmentLength] = useState<AppointmentOption>(appointmentOptions[1]);
  const [appointmentRepeats, setAppointmentRepeats] = useState(false);

  const [initialEvaluationLength, setInitialEvaluationLength] = useState<AppointmentOption>(
    initialEvaluationOptions[1],
  );

  const [conflicts, setConflicts] = useState<IConflict[]>([]);

  const { addToast } = useToasts();

  const duration = useMemo(() => {
    return appointmentType.value === 'STS' ? appointmentLength.value : initialEvaluationLength.value;
  }, [appointmentType.value, appointmentLength.value, initialEvaluationLength.value]);

  const type = useMemo(() => {
    return appointmentType.value === 'STS' ? 'session' : 'evaluation';
  }, [appointmentType.value]);

  const therapistSelectOptions: SelectOption[] | undefined = useMemo(() => {
    if (!contactState)
      return therapists?.map(therapist => ({ label: therapist.therapistName, value: therapist.therapistEmail }));
    return therapists
      ?.filter(therapist => therapist.statesLicensed.includes(contactState))
      .map(therapist => ({ label: therapist.therapistName, value: therapist.therapistEmail }));
  }, [contactState, therapists]);

  const therapist = useMemo(() => {
    const currentTherapist = therapists?.find(therapist => therapist.therapistEmail === selectedTherapist.value);
    if (currentTherapist) return currentTherapist;
    return therapistData;
  }, [therapistData, therapists, selectedTherapist]);

  const { data: availableTimesV2, isLoading } = useTherapistAvailableTimesV2({
    calendarID: therapist?.acuityCalendarID,
    date: date?.format('YYYY-MM-DD'),
    ...(appointmentRepeats && {
      reccuringNo: recurringTimes?.value !== RECURRING_FOREVER ? Number(recurringTimes.value) : 10,
    }),
    duration,
    type,
  });

  const availableTimesOptions = useMemo(() => {
    return availableTimesV2?.items?.map(availableTime => ({
      label: moment(availableTime.time).tz(displayTimezone.value).format('h:mm A'),
      value: getSelectedTimeDayJs(moment(availableTime.time).tz(displayTimezone.value).toISOString()).value,
      nonTzValue: getSelectedTimeDayJs(moment(availableTime.time).toISOString()).value,
    }));
  }, [availableTimesV2, displayTimezone.value, date]);

  const previousAvailableTimesOptionsRef = useRef<typeof availableTimesOptions>();

  const isLoadingState = isLoading || createAppointmentMutation.isLoading || createEvaluationMutation.isLoading;

  useEffect(() => {
    const newTime = availableTimesOptions?.find(availableTime => {
      return availableTime.nonTzValue === time?.nonTzValue;
    });

    if (newTime && availableTimes) {
      setTime(newTime);
    }
  }, [displayTimezone.value, availableTimes, date, availableTimesOptions]);

  useEffect(() => {
    setAppointmentType(appointmentTypeOptions[0]);
    setDate(moment());
    setAppointmentRepeats(false);
  }, [isOpen]);

  useEffect(() => {
    if (!availableTimesOptions) return;

    const areAvailableTimesEq = _.isEqual(previousAvailableTimesOptionsRef?.current, availableTimesOptions);

    const isTimeInOptions = availableTimesOptions.some(option => option?.value === time?.value);

    if (availableTimes && (!areAvailableTimesEq || !isTimeInOptions) && availableTimesOptions?.length) {
      setTime(availableTimesOptions[0]);
    }

    if (!availableTimes && !availableTimesOptions?.length) {
      setTime(APPOINTMENT_TIME_10_AM);
    }

    previousAvailableTimesOptionsRef.current = availableTimesOptions;
  }, [availableTimesOptions?.length, availableTimes]);

  useEffect(() => {
    setDisplayTimezone(() => {
      const guessTimezone =
        displayTimezoneOptions.find(timezone => {
          // for some reason mutating the moment to use the tz makes dst detection effective
          const timezoneArrayOffset = moment.tz(date, timezone.value).utcOffset();
          const appointmentTimeOffset = date?.utcOffset();
          return appointmentTimeOffset === timezoneArrayOffset;
        }) ?? displayTimezoneOptions[1];
      if (guessTimezone) {
        setIsGuessTimezoneReady(true);
      }
      return guessTimezone;
    });
  }, []);

  useEffect(() => {
    if (date && isGuessTimezoneReady) {
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).tz(displayTimezone.value);

      // we should always need to convert the time to the browser timezone
      // since the be should receive the date in the timezone the users is in, but in the time
      // the times are the ones the user sees different based on the modal timezone
      date.add(time?.value, 'hours').tz(dayjs.tz.guess());
    }
  }, [time, date, isGuessTimezoneReady, displayTimezone.value]);

  useEffect(() => {
    if (availableTimes && availableTimesV2?.items?.length === 0) {
      setAppointmentRepeats(false);
    }
  }, [availableTimes, availableTimesV2]);

  useEffect(() => {
    setConflicts([]);
  }, [appointmentRepeats]);

  useEffect(() => {
    setAppointmentRepeats(false);
  }, [appointmentType.value]);

  useEffect(() => {
    if (isLoadingState || !date) {
      setConflicts([]);
      return;
    }

    const isNowDST = moment().isDST();

    const mainDateHours = moment(date).hour();
    const mainDateMinutes = moment(date).minute();
    const dateTime = mainDateHours + mainDateMinutes / 60;
    const selectedDateFormatted = date?.toISOString();

    if (time?.value > dateTime) return;

    const isDateInAvailableTimes = (item: any) => {
      const itemTime = moment(item.time).tz(displayTimezone.value).toISOString();
      return itemTime === selectedDateFormatted;
    };

    const generateRecurringDates = () => {
      const numberOfWeeks = recurringTimes.value !== RECURRING_FOREVER ? Number(recurringTimes.value) + 1 : 10;
      return Array.from({ length: numberOfWeeks }, (_, i) => {
        const dateMoment = moment(selectedDateFormatted).add(i * 7, 'days');
        if (isNowDST && !dateMoment.isDST()) dateMoment.subtract(1, 'hour');
        if (!isNowDST && dateMoment.isDST()) dateMoment.add(1, 'hour');
        return dateMoment.toISOString();
      });
    };

    const checkFutureConflicts = (conflicts: IConflict[]) => {
      if (!availableTimesV2?.future) return;

      const recurringDates = generateRecurringDates();
      const recurringConflicts = new Map<string, IConflict>();

      conflicts.forEach(conflict => {
        recurringConflicts.set(conflict?.datetime, conflict);
      });

      availableTimesV2.future.forEach((report: any, reportIndex: number) => {
        const recurringDate = recurringDates[reportIndex];
        const dateMoment = moment(recurringDate);
        const isInWeek = dateMoment.isBetween(moment(report?.start), moment(report?.end), null, '[)');

        if (isInWeek) {
          const isAvailable = report.availability?.some((availableDate: string) => {
            const dateToCompare = moment(availableDate);
            return dateToCompare.toISOString() === recurringDate;
          });

          const isConflict = report.conflicts || (!availableTimes && !isAvailable);

          if (isConflict && !recurringConflicts.has(recurringDate)) {
            recurringConflicts.set(recurringDate, {
              datetime: recurringDate,
              type: 'futureConflict',
            });
          }
        }
      });

      const sortedConflicts = Array.from(recurringConflicts.values()).sort(
        (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime(),
      );

      setConflicts(sortedConflicts);
    };

    const handleOverride = () => {
      const isAvailable = availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = selectedDateFormatted;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: selectedDateFormatted,
          type: availableTimes ? 'appointmentExists' : 'futureConflict',
        });
      }

      setConflicts(Array.from(conflictMap.values()));

      if (appointmentRepeats) {
        checkFutureConflicts(Array.from(conflictMap.values()));
      }
    };

    const handleNonOverride = () => {
      const isAvailable =
        availableTimesV2?.items?.length === 0 || availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = selectedDateFormatted;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: selectedDateFormatted,
          type: availableTimes ? 'appointmentExists' : 'futureConflict',
        });
      }

      const selectedItem = availableTimesV2?.items?.find(isDateInAvailableTimes);
      selectedItem?.conflicts?.forEach((conflict: { datetime: string; type: string }) => {
        conflictMap.set(conflict?.datetime, {
          datetime: conflict?.datetime,
          type: availableTimes ? conflict?.type : 'futureConflict',
        });
      });

      setConflicts(Array.from(conflictMap.values()));

      if (appointmentRepeats) {
        checkFutureConflicts(Array.from(conflictMap.values()));
      }
    };

    if (!availableTimes) handleOverride();
    else handleNonOverride();
  }, [
    isLoadingState,
    availableTimes,
    availableTimesV2,
    date,
    time,
    recurringTimes,
    displayTimezone,
    appointmentRepeats,
    setConflicts,
  ]);

  const submit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    let formError = false;
    let recurringPayload = {};

    if (typeof time !== 'undefined' && date) {
      if (
        typeof contactEmail === 'undefined' ||
        typeof contactPhone === 'undefined' ||
        typeof contactTimeZone === 'undefined'
      ) {
        formError = true;
        const contactInfoErrorMessage = 'Primary contact information is missing';
        addToast(contactInfoErrorMessage, { appearance: 'error', autoDismiss: true });
      }

      if (appointmentType.value === AppointmentType.Therapy && appointmentRepeats) {
        recurringPayload = {
          recurring: appointmentRepeats,
          recurrenceTimes: recurringTimes.value !== RECURRING_FOREVER ? Number(recurringTimes.value) : undefined,
          recurringForever: recurringTimes.value === RECURRING_FOREVER ? true : undefined,
        };
      }

      if (!formError) {
        const payload = {
          clientID: clientId,
          dateTime: date,
          clientFirstName: preferredFirstName ? preferredFirstName : clientFirstName,
          clientLastName,
          contactEmail: contactEmail!,
          contactPhone: contactPhone!,
          contactTimeZone: contactTimeZone!,
          userTimezone: displayTimezone.value,
          therapistEmail: selectedTherapist.value,
          override: !availableTimes,
        };

        if (appointmentType.value === AppointmentType.Evaluation) {
          const evaluationPayload: CreateEvaluationPayload = {
            ...payload,
            acuityCalendarID: Number(therapist.acuityCalendarID),
            duration: initialEvaluationLength.value,
          };
          createEvaluation(evaluationPayload, {
            onSuccess: () => {
              queryClient.invalidateQueries();
              onClose();
            },
            onError: err => {
              queryClient.invalidateQueries();
              const error = getCueError(err);
              if ('conflicts' in error) setConflicts((error as any)?.conflicts);
              addToast(error?.message, { appearance: 'error', autoDismiss: true });
            },
          });
        } else {
          const appointmentPayload: CreateAppointmentPayload = {
            ...payload,
            duration: appointmentLength.value,
          };
          createAppointment(
            { ...appointmentPayload, ...recurringPayload },
            {
              onSuccess: async () => {
                queryClient.invalidateQueries();
                onClose();
              },
              onError: err => {
                queryClient.invalidateQueries();
                const error = getCueError(err);
                if ('conflicts' in error) setConflicts((error as any)?.conflicts);
                addToast(error?.message, { appearance: 'error', autoDismiss: true });
              },
            },
          );
        }
      }
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={submit}>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="calendar-check" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Create New Appointment
                </h3>
                <div tw="mt-2">
                  <div tw="text-sm text-center text-gray-500 leading-5">
                    Times are shown in -
                    <Menu
                      onChange={MenuItem => {
                        const selectedMenuItem = MenuItem as Record<string, string>;
                        setDisplayTimezone(selectedMenuItem);
                        if (date && selectedMenuItem) {
                          //true at the end of tz preserve the time already picked up
                          setDate(date.tz(selectedMenuItem.value, true));
                        }
                      }}
                      tw="px-1 inline"
                    >
                      <MenuLink to="#" data-testid="add-appointments-time-zone">
                        {displayTimezone?.label} Time Zone
                        <FontAwesomeIcon tw="text-sm ml-1" icon="caret-down" />
                      </MenuLink>
                      <MenuList tw="w-full">
                        {displayTimezoneOptions.map((option, index) => (
                          <MenuItem value={option} key={index}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </div>
                </div>
                <div tw="mt-3 flex justify-center text-center">
                  <Input
                    data-testid="add-appointment-available-times"
                    name="available-times"
                    id="available-times"
                    type="checkbox"
                    tw="mr-2"
                    checked={availableTimes}
                    disabled={isLoadingState}
                    onChange={e => setAvailableTimes(e.currentTarget.checked)}
                  />
                  <Label font="normal" htmlFor="available-times">
                    Only Show Available Times
                  </Label>
                </div>
                {!availableTimes && (
                  <div tw="mb-4 bg-[#FEE2E2] p-3 rounded-md">
                    Please note that viewing unavailable times removes all calendar protections and may result in a
                    schedule conflict.
                  </div>
                )}
                <div tw="mb-1">Therapist</div>
                <Select
                  aria-label="appointment-therapist-dropdown"
                  name="appointment-therapist-dropdown"
                  data-testid="add-appointment-therapist"
                  id="appointment-therapist-dropdown"
                  value={selectedTherapist}
                  disabled={!contactState || isLoadingState}
                  options={uniqBy(
                    [
                      ...(therapistSelectOptions ?? []),
                      {
                        label: `${therapist?.firstName} ${therapist?.lastName}`,
                        value: therapist?.therapistID,
                      },
                    ],
                    'value',
                  )}
                  onChange={(e: SelectOption) => setSelectedTherapist(e)}
                  tw="mb-2"
                />
                <div className="text-xs text-gray-500">
                  {contactState
                    ? `Only therapists with a state licensure of ${contactState} are displayed`
                    : 'Please add a state to the primary contact to be able to add to another therapist'}
                </div>

                <div tw="mt-6 mb-1">Appointment</div>
                <div tw="flex">
                  <div tw="w-full">
                    <div data-testid="add-appointment-type">
                      <Select
                        aria-label="appointment-type-dropdown"
                        name="appointment-type-dropdown"
                        value={appointmentType}
                        options={appointmentTypeOptions}
                        disabled={isLoadingState}
                        onChange={(e: { [key: string]: string }) => setAppointmentType(e)}
                      />
                    </div>
                  </div>
                </div>
                {appointmentType.value === AppointmentType.Therapy && (
                  <div tw="flex">
                    <div tw="w-full" data-testid="add-appointment-length">
                      <div data-testid="add-appointment-duration">
                        <Select
                          aria-label="appointment-type-dropdown"
                          name="appointment-type-dropdown"
                          value={appointmentLength}
                          options={appointmentOptions}
                          disabled={isLoadingState}
                          onChange={(selectedOption: AppointmentOption) => setAppointmentLength(selectedOption)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {appointmentType.value === AppointmentType.Evaluation && (
                  <div tw="flex">
                    <div tw="w-full" data-testid="add-initialEvaluation-length">
                      <div data-testid="add-initialEvaluation-duration">
                        <Select
                          aria-label="initialEvaluation-type-dropdown"
                          name="initialEvaluation-type-dropdown"
                          value={initialEvaluationLength}
                          options={initialEvaluationOptions}
                          disabled={isLoadingState}
                          onChange={(selectedOption: AppointmentOption) => setInitialEvaluationLength(selectedOption)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div tw="flex">
                  <div tw="w-1/2 mr-2">
                    <div data-testid="add-appointment-date">
                      <SingleDatePicker
                        id="add-appointment-date"
                        block
                        small
                        noBorder
                        openDirection="up"
                        numberOfMonths={1}
                        date={date}
                        hideKeyboardShortcutsPanel
                        focused={datePickerFocused}
                        disabled={isLoadingState}
                        onFocusChange={({ focused }) => setDatePickerFocused(focused)}
                        onDateChange={date => {
                          setDate(date);
                        }}
                      />
                    </div>
                  </div>
                  {availableTimes ? (
                    <div tw="w-1/2 ml-2">
                      {isLoading ? (
                        <div data-testid="add-appointment-loading" tw="flex items-center justify-center mt-3">
                          <LoadingText />
                        </div>
                      ) : (
                        <div>
                          {!availableTimesV2?.isRateLimited && availableTimesV2?.items?.length ? (
                            <>
                              <div className="w-full" data-testid="add-appointment-available-time">
                                <Select
                                  aria-label="time-dropdown"
                                  name="time-dropdown"
                                  spacing="small"
                                  options={availableTimesOptions}
                                  value={time}
                                  disabled={isLoadingState}
                                  onChange={(timeOption: { label: string; value: number; nonTzValue: number }) => {
                                    setTime(timeOption);
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <div tw="flex items-center justify-center text-gray-500" data-testid="not-available-times">
                              {availableTimesV2?.isRateLimited ? (
                                getWeekSummaryStatusDescription(availableTimesV2.rateLimitStatus)
                              ) : (
                                <>
                                  <span className="mt-2">No times available today</span>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div tw="w-1/2 ml-2">
                      <div data-testid="add-appointment-time">
                        <Select
                          aria-label="time-dropdown"
                          name="time-dropdown"
                          spacing="small"
                          value={time}
                          options={appointmentTimeOptions}
                          disabled={isLoadingState}
                          onChange={(timeOption: { label: string; value: number; nonTzValue: number }) => {
                            setTime(timeOption);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {appointmentType.value === AppointmentType.Therapy && (
                    <div tw="mt-4 flex">
                      <Input
                        data-testid="add-appointment-recurring"
                        name="recurring"
                        id="recurring"
                        type="checkbox"
                        tw="mr-2"
                        spacing={'tight'}
                        checked={appointmentRepeats}
                        disabled={
                          isLoadingState ||
                          (availableTimes && availableTimesV2?.isRateLimited) ||
                          (availableTimes && availableTimesV2?.items?.length === 0)
                        }
                        onChange={e => setAppointmentRepeats(e.currentTarget.checked)}
                      />
                      <Label font="normal" htmlFor="recurring">
                        Repeats
                      </Label>
                    </div>
                  )}
                  {appointmentRepeats && appointmentType.value === AppointmentType.Therapy && (
                    <>
                      <div tw="flex mt-5">
                        <div tw="w-full">
                          <div data-testid="add-appointment-recurring-time">
                            <Select
                              tw="mb-0"
                              name="recurrence-times"
                              options={recurrenceTimes}
                              defaultValue={recurrenceTimes[0]}
                              value={recurringTimes}
                              disabled={isLoadingState}
                              onChange={(recurrenceTimes: SelectOption) => setRecurringTimes(recurrenceTimes)}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {!isLoading && conflicts.length > 0 && (
                  <div className="mt-5">
                    <Conflicts
                      conflicts={conflicts}
                      availableTimes={availableTimes}
                      displayTimezone={displayTimezone.value}
                    />
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-4 sm:mt-5 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button
                  data-testid="add-appointment-submit"
                  type="submit"
                  variant="primary"
                  loading={isLoadingState}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  className={`${
                    isLoadingState ||
                    (availableTimes && !availableTimesOptions?.length) ||
                    (availableTimes && availableTimesV2?.isRateLimited)
                      ? 'cursor-not-allowed bg-gray-300'
                      : ''
                  }`}
                  disabled={
                    isLoadingState ||
                    (availableTimes && !availableTimesOptions?.length) ||
                    (availableTimes && availableTimesV2?.isRateLimited)
                  }
                >
                  Save
                </Button>
              </span>
              <span tw="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <Button
                  data-testid="add-appointment-close"
                  type="button"
                  variant="secondary"
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  className={`${isLoadingState ? 'cursor-not-allowed bg-gray-300' : ''}`}
                  disabled={isLoadingState}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
