import React from 'react';
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { FirehoseFilesUploaded } from 'domain/admin/types';
import { Link } from 'react-router-dom';
import { removeClientWordFromClientID } from 'utils/helpers';

const FilesUploaded = (event: FirehoseFilesUploaded) => {
  const filesCount = event.event.fileIds.length;
  const isLastEvent = event.isLastEvent;

  const firehoseEvent = event.event;

  return (
    <div data-testid="firehose-files-uploaded" className="relative flex mt-7 px-2">
      {!isLastEvent && (
        <span className="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
      )}
      <div className="flex items-baseline">
        <div className="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faFileCheck} className="mx-2 h-3 text-indigo-700" />
        </div>
        <div className="pl-4 text-sm">
          <div className="lg:flex font-semibold pb-1">
            <Link
              className="text-indigo-700"
              to={{ pathname: `/contacts/${removeClientWordFromClientID(firehoseEvent?.contactID)}` }}
            >
              {firehoseEvent?.contactFirstName} {firehoseEvent?.contactLastName}&nbsp;
            </Link>
            <span className="text-gray-500">
              uploaded {filesCount} {filesCount === 1 ? 'file' : 'files'}{' '}
              {!firehoseEvent?.isClientSelf && 'for the client'}
            </span>
            &nbsp;
            {!firehoseEvent?.isClientSelf && (
              <Link
                className="text-indigo-700"
                to={{ pathname: `/clients/${removeClientWordFromClientID(firehoseEvent?.clientID)}` }}
              >
                {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
              </Link>
            )}
            <span className="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>
          <div className="flex flex-col text-sm">
            {firehoseEvent?.fileNames.map(fileName => (
              <span key={fileName}>{fileName}</span>
            ))}
          </div>
          {firehoseEvent?.notes && (
            <>
              <div className="mt-2">Notes:</div>
              <div>{firehoseEvent?.notes}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilesUploaded;
