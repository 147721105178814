import { FirehoseEventPendingReschedule } from 'domain/admin/types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { removeClientWordFromClientID } from 'utils/helpers';
import 'twin.macro';
import { getRescheduleReasonDisplay } from 'domain/appointment/rescheduleReasons';

const PendingRescheduleRescheduled = (event: FirehoseEventPendingReschedule) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-pending-reschedule-rescheduled" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center justify-center bg-gray-200 rounded-full h-6 w-6">
          <svg
            style={{ width: '0.85em', height: '0.85em' }}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.994 1.2C11.994 0.54 11.46 0 10.8 0H1.2C0.54 0 0 0.54 0 1.2V8.4C0 9.06 0.54 9.6 1.2 9.6H9.6L12 12L11.994 1.2ZM9.6 7.2H2.4V6H9.6V7.2ZM9.6 5.4H2.4V4.2H9.6V5.4ZM9.6 3.6H2.4V2.4H9.6V3.6Z"
              fill="#524BCA"
            />
          </svg>
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1 text-indigo-700">
            <Link
              tw="text-indigo-700"
              to={
                firehoseEvent?.rescheduledBy === 'client'
                  ? { pathname: `/clients/${removeClientWordFromClientID(firehoseEvent.clientID)}` }
                  : { pathname: `/therapists/${firehoseEvent?.therapistEmail}` }
              }
            >
              {firehoseEvent?.rescheduledBy === 'client'
                ? `${firehoseEvent.clientFirstName} ${firehoseEvent.clientLastName}`
                : `${firehoseEvent?.therapistFirstName} ${firehoseEvent?.therapistLastName}`}
              &nbsp;
            </Link>
            <span tw="text-gray-500">{`rescheduled 1 ${
              event.event.originalAcuityAppointmentType === 'Initial Evaluation'
                ? 'evaluation'
                : 'speech therapy session'
            }`}</span>
            &nbsp;
            <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>
          <div>
            Original Appointment - 
            {dayjs(firehoseEvent?.originalAcuityAppointmentDatetime as string).format('MM/DD/YYYY - h:mm A')}{' '}
            {`(${firehoseEvent.duration} minutes)`}
          </div>
          <div className="capitalize">
            {`Rescheduled Reason: ${getRescheduleReasonDisplay(
              firehoseEvent.rescheduleReason,
              firehoseEvent.rescheduleReasonOther,
            )}`}
          </div>
          <div>
            New appointment date -{' '}
            {dayjs(firehoseEvent?.newAcuityAppointmentDatetime as string).format('MM/DD/YYYY - h:mm A')}{' '}
            {`(${firehoseEvent.newAcuityAppointmentDuration} minutes)`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingRescheduleRescheduled;
