import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Card, FormGroup, FormInline, Input, Label, Select, Spacer, Textarea } from '@expressable/ui-library';
import { CompleteTherapistInformation, Insurances } from '../types';
import timeZones from '../utils/us-time-zones.json';
import languagesOptions from 'utils/languages.json';
import specialties from '../utils/specialties.json';
import useCheckboxesState from '../hooks/use-checkboxes-state';
import 'twin.macro';
import styled from 'styled-components';
import {
  onlyLettersSpecialVowelsWhiteSpacesValidator,
  emptyValidator,
  forceUpperCase,
  validateGuaranteedHoursNumber,
} from '../utils/helpers';
import { handleArrowKeyDown } from 'pages/client/components/client-notes/note-form-content/session-note-form-content';
import { INSURANCES_ACCEPTED_ENTRY_ID, useContentfulEntry } from 'hooks/use-ContentfulData';
import usStatesOptions from 'utils/us-states.json';
import TherapistInfoReadOnly from './therapist-info-read-only';
import usePermissions from 'hooks/use-permissions';
import formatZoomLink from 'utils/zoom-link';
import therapistTypes from 'domain/therapist/therapistTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { handleDecimalNumber } from 'pages/client/components/client-goals-modal';
import { Badge } from '@expressable/ui-library';

export const InputNumberStyled = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];

interface SelectOption {
  label: string;
  value: string;
}

interface EditTherapistInfoForm {
  therapistEmail: string;
  sequoiaID: string;
  acuityCalendarID: string;
  firstName: string;
  preferredFirstName?: string;
  lastName: string;
  therapistTimeZone: SelectOption | null;
  specialties: string[];
  acceptingNewPatients: 'yes' | 'no';
  statesLicensed: SelectOption[];
  licenseNumbers?: string;
  languages: SelectOption[];
  therapistProfile: string;
  zoomLink: string;
  consultingTherapist: 'yes' | 'no';
  npi: string;
  status: SelectOption;
  committedHours: number;
  therapistType: SelectOption | null;
  isPRN: 'yes' | 'no';
  slackUserId?: string;
  useSlackConversations: 'yes' | 'no';
  evaluationLimitPerDay?: number;
  evaluationLimitPerWeek?: number;
  directHourGoals?: number;
  directHourAttendanceRate?: number;
  consecutiveApptsLimit?: number;
  aiTranscriptProcessing: 'yes' | 'no';
  therapistState: SelectOption | null;
}

const convertTherapistInfoToFormFields = (data: CompleteTherapistInformation): EditTherapistInfoForm => {
  const {
    therapistEmail,
    sequoiaID,
    acuityCalendarID,
    firstName,
    preferredFirstName,
    lastName,
    specialties,
    acceptingNewPatients,
    therapistProfile,
    consultingTherapist,
    npi,
    licenseNumbers,
    committedHours,
    zoomLink,
    isPRN,
    slackUserId,
    useSlackConversations,
    evaluationLimitPerDay,
    evaluationLimitPerWeek,
    directHourGoals,
    directHourAttendanceRate,
    consecutiveApptsLimit,
    aiTranscriptProcessing,
    therapistState
  } = data;

  const therapistTimeZone: SelectOption | null = timeZones.find(t => t.value === data.therapistTimeZone) ?? null;
  const statesLicensed = data.statesLicensed.map(license => ({
    value: license,
    label: license,
  })) as SelectOption[];
  const languages = data.languages?.map(language =>
    languagesOptions.find(({ value }) => value === (language as { code: string; name: string }).code),
  ) as SelectOption[];
  const status: SelectOption = STATUS_OPTIONS.find(s => s.value === data.status) ?? {
    label: '',
    value: '',
  };
  const therapistType = therapistTypes.find(x => x.value === data.therapistType) ?? null;

  return {
    therapistEmail,
    sequoiaID,
    acuityCalendarID,
    firstName,
    preferredFirstName,
    lastName,
    therapistTimeZone,
    specialties,
    acceptingNewPatients: acceptingNewPatients ? 'yes' : 'no',
    statesLicensed,
    licenseNumbers: licenseNumbers ?? '',
    languages,
    therapistProfile,
    zoomLink: zoomLink,
    consultingTherapist: consultingTherapist ? 'yes' : 'no',
    npi: npi ? npi : '',
    status,
    committedHours: committedHours ? committedHours : 0,
    therapistType,
    isPRN: isPRN ? 'yes' : 'no',
    slackUserId,
    useSlackConversations: useSlackConversations ? 'yes' : 'no',
    evaluationLimitPerDay,
    evaluationLimitPerWeek,
    directHourGoals,
    directHourAttendanceRate,
    consecutiveApptsLimit,
    aiTranscriptProcessing: aiTranscriptProcessing ? 'yes' : 'no',
    therapistState: therapistState ? { value: therapistState, label: therapistState } : null,
  };
};

const convertFormFieldsToTherapistInfo = (data: EditTherapistInfoForm): CompleteTherapistInformation => {
  const {
    therapistEmail,
    sequoiaID,
    acuityCalendarID,
    firstName,
    preferredFirstName,
    lastName,
    therapistTimeZone,
    specialties,
    acceptingNewPatients,
    statesLicensed,
    licenseNumbers,
    languages,
    therapistProfile,
    consultingTherapist,
    npi,
    status,
    committedHours,
    therapistType,
    zoomLink,
    isPRN,
    slackUserId,
    useSlackConversations,
    evaluationLimitPerDay,
    evaluationLimitPerWeek,
    directHourGoals,
    directHourAttendanceRate,
    consecutiveApptsLimit,
    aiTranscriptProcessing,
    therapistState
  } = data;

  if (therapistTimeZone === null) {
    throw new Error(`${therapistEmail}: therapistTimeZone cannot be null`);
  }

  return {
    therapistEmail,
    sequoiaID,
    acuityCalendarID,
    firstName,
    preferredFirstName,
    lastName,
    therapistTimeZone: therapistTimeZone.value,
    specialties: typeof specialties === 'string' ? ([specialties] as string[]) : specialties,
    acceptingNewPatients: acceptingNewPatients === 'yes',
    statesLicensed: statesLicensed.map(({ value }) => value),
    licenseNumbers,
    languages: languages.map(({ value }) => value),
    therapistProfile,
    zoomLink: zoomLink,
    consultingTherapist: consultingTherapist === 'yes',
    npi,
    status: status.value,
    committedHours: committedHours ? Number(committedHours) : 0,
    therapistType: therapistType?.value,
    isPRN: isPRN === 'yes',
    slackUserId,
    useSlackConversations: useSlackConversations === 'yes',
    evaluationLimitPerDay: evaluationLimitPerDay ? Number(evaluationLimitPerDay) : undefined,
    evaluationLimitPerWeek: evaluationLimitPerWeek ? Number(evaluationLimitPerWeek) : undefined,
    directHourGoals: directHourGoals ? Number(directHourGoals) : undefined,
    directHourAttendanceRate: directHourAttendanceRate ? Number(directHourAttendanceRate) : undefined,
    consecutiveApptsLimit: consecutiveApptsLimit ? Number(consecutiveApptsLimit) : undefined,
    aiTranscriptProcessing: aiTranscriptProcessing === 'yes',
    therapistState: therapistState?.value,
  };
};

export interface EditTherapistInfoFormProps {
  therapistData: CompleteTherapistInformation;
  onSave: (therapistData: CompleteTherapistInformation) => void;
  onCancel?: () => void;
  isEmailDisabled?: boolean;
  isLoading?: boolean;
}

const TherapistInfoForm = (props: EditTherapistInfoFormProps) => {
  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
  } = useForm<EditTherapistInfoForm>();
  const { therapistData: originalTherapistData, onSave, onCancel, isEmailDisabled = false, isLoading = false } = props;
  const { isAdminOrClinicalManager } = usePermissions();
  const watchTherapistType = watch('therapistType');
  const watchDirectHourGoals = watch('directHourGoals');
  const watchDirectHourAttendanceRate = watch('directHourAttendanceRate');

  const { cueSlackConversation: isSlackConversationAvailable } = useFlags();

  if (!originalTherapistData) {
    return null;
  }
  const therapistData = convertTherapistInfoToFormFields(originalTherapistData);

  const [selectedSpecialties, setSelectedSpecialties] = useCheckboxesState(originalTherapistData.specialties);

  const [insuranceOptionsEntry, , fetchContentfulData] = useContentfulEntry<Insurances>(INSURANCES_ACCEPTED_ENTRY_ID);

  const insuranceOptions: SelectOption[] | undefined = insuranceOptionsEntry?.fields?.insurances?.map(insurance => ({
    label: insurance,
    value: insurance,
  }));

  const whiteSpaceRegex = /\s/g;

  useEffect(() => {
    fetchContentfulData();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setValue('isPRN', therapistData.isPRN === 'yes' ? 'yes' : 'no');
      setValue('useSlackConversations', therapistData.useSlackConversations === 'yes' ? 'yes' : 'no');
      setValue('aiTranscriptProcessing', therapistData.aiTranscriptProcessing === 'yes' ? 'yes' : 'no');
    }
  }, [isLoading]);

  useEffect(() => {
    if (watchTherapistType?.value === 'Salary') {
      setValue('isPRN', 'no');
    }
  }, [watchTherapistType]);

  const LICENSES_OPTIONS = [
    {
      label: 'States',
      options: usStatesOptions,
    },
    {
      label: 'Insurance Plans',
      options: insuranceOptions,
    },
  ];

  useEffect(() => {
    if (watchDirectHourGoals && !dirtyFields?.directHourAttendanceRate && !therapistData?.directHourAttendanceRate) {
      setValue('directHourAttendanceRate', 80); // default is 80% for attendance rate
    }
  }, [watchDirectHourGoals]);

  const onSubmit = (data: EditTherapistInfoForm) => {
    onSave(convertFormFieldsToTherapistInfo(data));
  };

  const updateAutoFilledFields = () => {
    const formatedFirstName = getValues('firstName').replace(whiteSpaceRegex, '').toLowerCase();
    const formatedLastName = getValues('lastName').replace(whiteSpaceRegex, '').toLowerCase();
    setValue('therapistProfile', `https://www.expressable.com/therapists/${formatedFirstName}-${formatedLastName}`);

    const therapistName = `${formatedFirstName} ${formatedLastName}`;
    setValue('zoomLink', formatZoomLink(therapistName));
  };

  const firstNameRegister = register('firstName', {
    required: 'first name is required',
    validate: {
      emptyValidator,
      onlyLettersSpecialVowelsWhiteSpacesValidator,
    },
  });
  const preferredNameRegister = register('preferredFirstName', {
    validate: {
      onlyLettersSpecialVowelsWhiteSpacesValidator,
    },
  });
  const lastNameRegister = register('lastName', {
    required: true,
    validate: {
      emptyValidator,
      onlyLettersSpecialVowelsWhiteSpacesValidator,
    },
  });
  const specialtiesRegister = register('specialties', {
    required: true,
  });

  if (isAdminOrClinicalManager) {
    return (
      <>
        <Spacer size="md" />
        <Card tw="mb-4 px-8">
          <div tw="text-2xl font-semibold mb-9 mt-4">Therapist Information</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div tw="flex flex-col sm:flex-row flex-wrap">
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="firstName">First Name</Label>
                <Input
                  type="text"
                  id="firstName"
                  data-testid="firstName"
                  tw="w-full"
                  {...firstNameRegister}
                  defaultValue={therapistData.firstName}
                  onChange={e => {
                    firstNameRegister.onChange(e);
                    updateAutoFilledFields();
                  }}
                  error={errors.firstName}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3 sm:pl-3">
                <Label htmlFor="preferredFirstName">Preferred Name</Label>
                <Input
                  type="text"
                  id="preferredFirstName"
                  data-testid="preferredFirstName"
                  tw="w-full"
                  {...preferredNameRegister}
                  defaultValue={therapistData.preferredFirstName}
                  onChange={e => {
                    preferredNameRegister.onChange(e);
                    updateAutoFilledFields();
                  }}
                  error={errors.preferredFirstName}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3 sm:pl-3">
                <Label htmlFor="lastName">Last Name</Label>
                <Input
                  type="text"
                  id="lastName"
                  data-testid="lastName"
                  tw="w-full"
                  {...lastNameRegister}
                  defaultValue={therapistData.lastName}
                  onChange={e => {
                    lastNameRegister.onChange(e);
                    updateAutoFilledFields();
                  }}
                  error={errors.lastName}
                />
              </FormGroup>
            </div>
            <FormGroup>
              <Label htmlFor="therapistTimeZone">Therapist Time Zone</Label>
              <div tw="w-full md:w-1/3">
                <Controller
                  name="therapistTimeZone"
                  data-testid="therapistTimeZone"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="therapistTimeZone"
                      options={timeZones}
                      isSearchable
                      error={errors.therapistTimeZone}
                    />
                  )}
                  defaultValue={therapistData.therapistTimeZone}
                  rules={{ required: true }}
                />
              </div>
            </FormGroup>
            <FormGroup tw="md:w-108 pr-1  sm:w-full">
              <Label htmlFor="therapistEmail">Expressable Email</Label>
              <Input
                type="email"
                id="therapistEmail"
                data-testid="therapistEmail"
                {...register('therapistEmail', { required: true })}
                defaultValue={therapistData.therapistEmail}
                error={errors.therapistEmail}
                disabled={isEmailDisabled}
                tw="w-full md:w-2/3"
              />
            </FormGroup>
            <FormGroup tw="md:w-9/12 pr-1 sm:w-full">
              <Label htmlFor="sequoiaID">Sequoia ID</Label>
              <Input
                type="text"
                id="sequoiaID"
                data-testid="sequoiaID"
                {...register('sequoiaID', {
                  validate: val => {
                    return !val || /^[0-9A-Z]+$/.test(val) || 'only numbers and uppercase letters allowed';
                  },
                  required: true,
                })}
                defaultValue={therapistData.sequoiaID}
                error={errors.sequoiaID}
                tw="w-full md:w-2/3"
                spacing="tight"
                onInput={forceUpperCase}
              />
              <div tw="text-gray-500 text-xs mt-2.5 mb-4">
                Please contact Karla Barahona (
                <a href="mailto:karla.barahona@expressable.io" tw="text-indigo-700">
                  karla.barahona@expressable.io
                </a>
                ) to get an ID
              </div>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="therapistState">Therapist State of Residence</Label>
              <div tw="w-full md:w-2/3">
                <Controller
                  name="therapistState"
                  data-testid="therapistState"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="therapistState"
                      options={usStatesOptions}
                      isSearchable
                      error={errors.therapistState}
                    />
                  )}
                  defaultValue={therapistData.therapistState}
                  rules={{ required: true }}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="statesLicensed">Licensed States</Label>
              <div tw="w-full md:w-2/3">
                <Controller
                  name="statesLicensed"
                  data-testid="statesLicensed"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="statesLicensed"
                      options={LICENSES_OPTIONS}
                      isSearchable
                      isMulti
                      error={errors.statesLicensed}
                    />
                  )}
                  defaultValue={therapistData.statesLicensed}
                  rules={{ required: true }}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="licenseNumbers">License Numbers</Label>
              <Textarea
                id="licenseNumbers"
                data-testid="licenseNumbers"
                tw="w-full md:w-2/3"
                {...register('licenseNumbers')}
                defaultValue={therapistData.licenseNumbers}
                error={errors.licenseNumbers}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="languages">Languages</Label>
              <div tw="w-full md:w-2/3">
                <Controller
                  name="languages"
                  data-testid="languages"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="languages"
                      options={languagesOptions}
                      isSearchable
                      isMulti
                      error={errors.languages}
                    />
                  )}
                  defaultValue={therapistData.languages}
                  rules={{
                    required: true,
                    validate: selected =>
                      selected.some(({ value }: { value: string }) => value === 'en') ||
                      'English is a required language.',
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="therapistProfile">Therapist Profile</Label>
              <Input
                type="text"
                id="therapistProfile"
                data-testid="therapistProfile"
                tw="w-full md:w-2/3"
                {...register('therapistProfile', { required: true })}
                defaultValue={therapistData.therapistProfile}
                error={errors.therapistProfile}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="zoomLink">Zoom Link</Label>
              <Input
                type="text"
                id="zoomLink"
                data-testid="zoomLink"
                error={errors.zoomLink}
                {...register('zoomLink', { required: true })}
                defaultValue={therapistData.zoomLink}
                tw="w-full md:w-2/3"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="acuityCalendarID">Acuity Calendar ID</Label>
              <InputNumberStyled>
                <Input
                  type="number"
                  step="1"
                  id="acuityCalendarID"
                  data-testid="acuityCalendarID"
                  tw="w-full sm:w-1/3"
                  onWheel={event => event.currentTarget.blur()}
                  onKeyDown={handleArrowKeyDown}
                  onKeyPress={handleDecimalNumber}
                  {...register('acuityCalendarID', { required: true })}
                  defaultValue={therapistData.acuityCalendarID}
                  error={errors.acuityCalendarID}
                  spacing="tight"
                />
              </InputNumberStyled>
              <div tw="text-gray-500 text-xs mt-2.5 mb-4">
                Please contact Ryan Hinojosa (
                <a href="mailto:ryan@expressable.io" tw="text-indigo-700">
                  ryan@expressable.io
                </a>
                ) to get an ID if you don&apos;t know the Acuity account information
              </div>
            </FormGroup>
            <FormGroup tw="mb-6">
              <Label htmlFor="npi">NPI</Label>
              <InputNumberStyled>
                <Input
                  type="number"
                  onWheel={event => event.currentTarget.blur()}
                  onKeyDown={handleArrowKeyDown}
                  id="npi"
                  data-testid="npi"
                  tw="w-full sm:w-1/3"
                  {...register('npi', {
                    validate: val => {
                      const validator = /^[0-9]{10}$/;
                      return !val || validator.test(val) || '10-digit number is allowed';
                    },
                    required: true,
                  })}
                  defaultValue={therapistData.npi}
                  error={errors.npi}
                  spacing="tight"
                />
              </InputNumberStyled>
            </FormGroup>
            <h4 tw="mb-2 text-sm font-semibold">Clinical Competencies</h4>
            {specialties.map(specialty => (
              <div key={specialty}>
                <FormGroup type="inline" tw="items-baseline my-1">
                  <Input
                    type="checkbox"
                    id={specialty}
                    data-testid="specialties"
                    tw="mb-0"
                    {...specialtiesRegister}
                    value={specialty}
                    checked={selectedSpecialties.includes(specialty)}
                    onChange={e => {
                      specialtiesRegister.onChange(e);
                      setSelectedSpecialties(specialty, e.target.checked);
                    }}
                    spacing="tight"
                  />
                  <Label tw="font-normal mb-0 ml-2 text-sm" htmlFor={specialty}>
                    {specialty}
                  </Label>
                </FormGroup>
              </div>
            ))}
            {errors.specialties && <span className="text-red-500 text-xs italic">{'Please fill out this field.'}</span>}
            <FormGroup tw="mt-4">
              <Label htmlFor="status">Status</Label>
              <div tw="w-full md:w-2/3">
                <Controller
                  name="status"
                  data-testid="status"
                  control={control}
                  render={({ field }) => <Select {...field} id="status" options={STATUS_OPTIONS} isSearchable />}
                  defaultValue={therapistData.status}
                  rules={{ required: true }}
                />
              </div>
            </FormGroup>
            <FormGroup tw="mb-5">
              <Label htmlFor="committedHours">Guaranteed Hours</Label>
              <InputNumberStyled>
                <Input
                  type="number"
                  step="0.1"
                  id="committedHours"
                  data-testid="committedHours"
                  tw="w-full sm:w-1/3"
                  onWheel={event => event.currentTarget.blur()}
                  onKeyDown={handleArrowKeyDown}
                  onPaste={event => event.preventDefault()}
                  {...register('committedHours', {
                    required: true,
                    validate: { validateGuaranteedHoursNumber },
                  })}
                  defaultValue={therapistData.committedHours}
                  error={errors.committedHours}
                  spacing="tight"
                />
              </InputNumberStyled>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="therapistType">Therapist Type</Label>
              <Controller
                name="therapistType"
                data-testid="therapistType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="therapistType"
                    options={therapistTypes}
                    isSearchable
                    tw="w-full sm:w-1/3"
                    error={errors.therapistType}
                  />
                )}
                defaultValue={therapistData.therapistType}
                rules={{ required: true }}
              />
            </FormGroup>
            {watchTherapistType?.value === 'Hourly' && (
              <FormGroup tw="mb-4">
                <h4 tw="mb-2 text-sm font-semibold">PRN</h4>
                <div tw="flex flex-row">
                  <FormInline tw="items-baseline mr-12">
                    <Input
                      type="radio"
                      id="isPRNYes"
                      data-testid="isPRNYes"
                      {...register('isPRN')}
                      value="yes"
                      spacing="tight"
                    />
                    <Label font="normal" htmlFor="isPRNYes" tw="ml-2">
                      Yes
                    </Label>
                  </FormInline>
                  <FormInline tw="items-baseline">
                    <Input
                      type="radio"
                      id="isPRNNo"
                      data-testid="isPRNNo"
                      {...register('isPRN')}
                      value="no"
                      spacing="tight"
                    />
                    <Label font="normal" htmlFor="isPRNNo" tw="ml-2">
                      No
                    </Label>
                  </FormInline>
                </div>
              </FormGroup>
            )}
            {isSlackConversationAvailable && (
              <>
                <FormGroup tw="mt-7">
                  <Label htmlFor="slackUserId">Slack User ID</Label>
                  <Input
                    type="text"
                    step="1"
                    id="slackUserId"
                    data-testid="slackUserId"
                    tw="w-full sm:w-1/3"
                    onWheel={event => event.currentTarget.blur()}
                    {...register('slackUserId', {
                      required: true,
                      pattern: {
                        value: /^[UW][0-9A-Z]{8,11}$/,
                        message: 'Invalid Slack User ID format',
                      },
                    })}
                    defaultValue={therapistData.slackUserId}
                    error={errors.slackUserId}
                    spacing="tight"
                  />
                  <div tw="text-gray-500 text-xs mt-2.5 mb-5">
                    Please contact Ryan Hinojosa (
                    <a href="mailto:ryan@expressable.io" tw="text-indigo-700">
                      ryan@expressable.io
                    </a>
                    ) to get an ID if you don&apos;t know the Slack User ID
                  </div>
                </FormGroup>
                <FormGroup tw="mb-7">
                  <h4 tw="mb-2 text-sm font-semibold">Slack Conversations Enabled</h4>
                  <div tw="flex flex-row">
                    <FormInline tw="items-baseline mr-12">
                      <Input
                        type="radio"
                        id="edit-therapist-info-form-slack-conversation-yes"
                        data-testid="edit-therapist-info-form-slack-conversation-yes"
                        {...register('useSlackConversations')}
                        value="yes"
                        spacing="tight"
                      />
                      <Label font="normal" htmlFor="edit-therapist-info-form-slack-conversation-yes" tw="ml-2">
                        Yes
                      </Label>
                    </FormInline>
                    <FormInline tw="items-baseline">
                      <Input
                        type="radio"
                        id="edit-therapist-info-form-slack-conversation-no"
                        data-testid="edit-therapist-info-form-slack-conversation-no"
                        {...register('useSlackConversations')}
                        value="no"
                        spacing="tight"
                      />
                      <Label font="normal" htmlFor="edit-therapist-info-form-slack-conversation-no" tw="ml-2">
                        No
                      </Label>
                    </FormInline>
                  </div>
                </FormGroup>
              </>
            )}
            <>
              <FormGroup tw="mb-7">
                <div className="flex flex-row gap-2">
                  <h4 tw="mb-2 text-sm font-semibold">AI Enabled</h4>
                  <Badge variant="waiting" tw="uppercase h-4">
                    beta
                  </Badge>
                </div>
                <div tw="flex flex-row">
                  <FormInline tw="items-baseline mr-12">
                    <Input
                      type="radio"
                      id="edit-therapist-info-form-ai-transcript-processing-yes"
                      data-testid="edit-therapist-info-form-ai-transcript-processing-yes"
                      {...register('aiTranscriptProcessing')}
                      value="yes"
                      spacing="tight"
                    />
                    <Label font="normal" htmlFor="edit-therapist-info-form-ai-transcript-processing-yes" tw="ml-2">
                      Yes
                    </Label>
                  </FormInline>
                  <FormInline tw="items-baseline">
                    <Input
                      type="radio"
                      id="edit-therapist-info-form-ai-transcript-processing-no"
                      data-testid="edit-therapist-info-form-ai-transcript-processing-no"
                      {...register('aiTranscriptProcessing')}
                      value="no"
                      spacing="tight"
                    />
                    <Label font="normal" htmlFor="edit-therapist-info-form-ai-transcript-processing-no" tw="ml-2">
                      No
                    </Label>
                  </FormInline>
                </div>
              </FormGroup>
            </>

            <div tw="text-xl font-semibold mt-7">Rate Limiting</div>

            <FormGroup tw="mt-7">
              <h4 tw="mb-2 text-sm font-semibold">Accepting New Clients</h4>
              <div tw="flex flex-row">
                <FormInline tw="items-baseline mr-12">
                  <Input
                    type="radio"
                    id="acceptingNewPatientsYes"
                    data-testid="acceptingNewPatientsYes"
                    {...register('acceptingNewPatients')}
                    value="yes"
                    defaultChecked={therapistData.acceptingNewPatients === 'yes'}
                    spacing="tight"
                  />
                  <Label font="normal" htmlFor="acceptingNewPatientsYes" tw="ml-2">
                    Yes
                  </Label>
                </FormInline>
                <FormInline tw="items-baseline">
                  <Input
                    type="radio"
                    id="acceptingNewPatientsNo"
                    data-testid="acceptingNewPatientsNo"
                    {...register('acceptingNewPatients')}
                    value="no"
                    defaultChecked={therapistData.acceptingNewPatients === 'no'}
                    spacing="tight"
                  />
                  <Label font="normal" htmlFor="acceptingNewPatientsNo" tw="ml-2">
                    No
                  </Label>
                </FormInline>
              </div>
            </FormGroup>

            <FormGroup tw="mt-7">
              <Label>Initial Evaluation Limits</Label>

              <div tw="flex items-center mt-4">
                <InputNumberStyled>
                  <Input
                    type="number"
                    step="1"
                    id="evaluationLimitPerDay"
                    data-testid="evaluationLimitPerDay"
                    tw="w-12"
                    onWheel={event => event.currentTarget.blur()}
                    onKeyDown={handleArrowKeyDown}
                    {...register('evaluationLimitPerDay', {
                      validate: value => numberBetweenRangeValidator(value, 8),
                    })}
                    onKeyPress={handleDecimalNumber}
                    defaultValue={therapistData?.evaluationLimitPerDay}
                    spacing="tight"
                  />
                </InputNumberStyled>
                <Label tw="font-normal ml-2 text-sm">per day maximum</Label>
              </div>
              {errors?.evaluationLimitPerDay && (
                <span className="text-red-500 text-xs italic">{errors.evaluationLimitPerDay?.message}</span>
              )}

              <div tw="flex items-center mt-4">
                <InputNumberStyled>
                  <Input
                    type="number"
                    step="1"
                    id="evaluationLimitPerWeek"
                    data-testid="evaluationLimitPerWeek"
                    tw="w-12"
                    onWheel={event => event.currentTarget.blur()}
                    onKeyDown={handleArrowKeyDown}
                    {...register('evaluationLimitPerWeek', {
                      validate: value => numberBetweenRangeValidator(value, 40),
                    })}
                    onKeyPress={handleDecimalNumber}
                    defaultValue={therapistData?.evaluationLimitPerWeek}
                    spacing="tight"
                  />
                </InputNumberStyled>
                <Label tw="font-normal ml-2 text-sm">per week maximum</Label>
              </div>
              {errors?.evaluationLimitPerWeek && (
                <span className="text-red-500 text-xs italic">{errors.evaluationLimitPerWeek?.message}</span>
              )}
            </FormGroup>

            <FormGroup tw="mt-7">
              <Label>Direct Hour Limits</Label>
              <div tw="text-gray-500 text-sm mt-2">
                Direct hours are a combination of evaluation and sessions, taking the duration of all appointments
                scheduled into account. Attendance rate assumptions are also included.
              </div>

              <div tw="flex items-center mt-7">
                <InputNumberStyled>
                  <Input
                    type="number"
                    step="0.5"
                    id="directHourGoals"
                    data-testid="directHourGoals"
                    tw="w-14"
                    onWheel={event => event.currentTarget.blur()}
                    onKeyDown={handleArrowKeyDown}
                    {...register('directHourGoals', {
                      validate: value => numberBetweenRangeValidator(value, 40),
                    })}
                    defaultValue={therapistData?.directHourGoals}
                    spacing="tight"
                  />
                </InputNumberStyled>
                <Label tw="font-normal ml-2 text-sm">direct hour goals</Label>
              </div>
              {errors?.directHourGoals && (
                <span className="text-red-500 text-xs italic">{errors.directHourGoals?.message}</span>
              )}

              <div tw="flex items-center mt-4">
                <InputNumberStyled>
                  <Input
                    type="number"
                    step="0.5"
                    id="directHourAttendanceRate"
                    data-testid="directHourAttendanceRate"
                    tw="w-14"
                    onWheel={event => event.currentTarget.blur()}
                    onKeyDown={handleArrowKeyDown}
                    {...register('directHourAttendanceRate', {
                      required: !!watchDirectHourGoals,
                      validate: value => numberBetweenRangeValidator(value, 100, 1),
                    })}
                    defaultValue={therapistData?.directHourAttendanceRate}
                    spacing="tight"
                  />
                </InputNumberStyled>
                <Label tw="font-normal ml-2 text-sm">% projected attendance rate</Label>
              </div>
              {errors?.directHourAttendanceRate && (
                <span className="text-red-500 text-xs italic">
                  {errors.directHourAttendanceRate?.message || 'Please fill out this field.'}
                </span>
              )}

              <SchedulingLimitPerWeek
                directHourGoals={watchDirectHourGoals}
                directHourAttendanceRate={watchDirectHourAttendanceRate}
              />
            </FormGroup>

            <FormGroup tw="mb-8 mt-7">
              <Label htmlFor="consecutiveApptsLimit">Consecutive Hour Limits</Label>

              <Label tw="font-normal text-sm text-gray-500">
                How many consecutive appointments (by duration) can be booked without a break?
              </Label>

              <div tw="flex items-center mt-4">
                <InputNumberStyled>
                  <Input
                    type="number"
                    min="1"
                    max="6"
                    step="0.5"
                    id="consecutiveApptsLimit"
                    data-testid="consecutiveApptsLimit"
                    tw="w-12"
                    onWheel={event => event.currentTarget.blur()}
                    onKeyDown={handleArrowKeyDown}
                    {...register('consecutiveApptsLimit', {
                      validate: value => numberBetweenRangeValidator(value, 6, 1),
                    })}
                    defaultValue={therapistData?.consecutiveApptsLimit}
                    spacing="tight"
                  />
                </InputNumberStyled>
                <Label tw="font-normal ml-2 text-sm">hours</Label>
              </div>
              {errors?.consecutiveApptsLimit && (
                <span className="text-red-500 text-xs italic">{errors.consecutiveApptsLimit?.message}</span>
              )}
            </FormGroup>

            <div tw="mt-10">
              <Button tw="mr-5" variant="primary" type="submit" loading={isLoading} data-testid="submitButton">
                Save
              </Button>
              {onCancel && (
                <Button variant="secondary" type="button" onClick={onCancel} data-testid="cancelButton">
                  Cancel
                </Button>
              )}
            </div>
          </form>
        </Card>
      </>
    );
  }

  return (
    <>
      <TherapistInfoReadOnly therapistData={originalTherapistData} />
    </>
  );
};

export default TherapistInfoForm;

const numberBetweenRangeValidator = (value: number | undefined, maximum: number, minimum = 0) => {
  return value && (value < minimum || value > maximum) ? `The number must be between ${minimum} and ${maximum}` : true;
};

const getHourSchedulingLimitPerWeek = (directHourGoals?: number, directHourAttendanceRate?: number) => {
  if (!directHourGoals || !directHourAttendanceRate) {
    return undefined;
  }

  const calculatedValue = (directHourGoals / directHourAttendanceRate) * 100;

  // Simplified rounding to nearest half
  return Math.ceil(calculatedValue * 2) / 2;
};

export const SchedulingLimitPerWeek = ({
  directHourGoals,
  directHourAttendanceRate,
}: {
  directHourGoals?: number;
  directHourAttendanceRate?: number;
}) => {
  return (
    <div tw="flex items-center mt-5 bg-indigo-50 rounded-md p-3 w-max text-sm">
      <span tw="font-semibold">
        {getHourSchedulingLimitPerWeek(directHourGoals, directHourAttendanceRate) ?? 'No limit'}
      </span>
      &nbsp;hour scheduling limit per week
    </div>
  );
};
