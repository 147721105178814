import { FC } from 'react';
import { Form } from 'components/Forms';
import { TimeSlot } from 'components/therapistMatcher/data';
import { ClientTab } from 'pages/client/client';
import { AppointmentType, SessionType } from 'domain/appointment/constants';

import { useClientMatchingForm } from './hooks/useClientMatchingForm';
import { AppointmentTypeStep } from './components/steps/AppoinmentTypeStep';
import { SessionTypeStep } from './components/steps/SessionTypeStep';
import { EvaluationSchedulingStep } from './components/steps/EvaluationSchedulingStep';
import { SummaryStep } from './components/steps/SummaryStep';
import { NoTherapistsMessage } from './components/no-therapist-message';
import { TherapySchedulingStep } from './components/steps/TherapistSchedulingStep';
import { FilterTherapistsStep } from './components/steps/FilterTherapistStep';

import 'twin.macro';
import { isNull, isUndefined } from 'lodash';

type Props = {
  clientId: string;
  setTab: React.Dispatch<React.SetStateAction<ClientTab>>;
};

export type ClientMatchingState = {
  appointmentType: AppointmentType;
  sessionType?: SessionType;
  slot: {
    timeSlot: TimeSlot;
  };
} | null;

export const ClientMatchingUI: FC<Props> = ({ clientId, setTab }) => {
  const {
    form,
    appointmentType,
    sessionType,
    canGoNext,
    filters,
    onChangeFilters,
    autoSelectTimezone,
    setAutoSelectTimezone,
    initialEvaluationOptions,
    onChangeEvalOptions,
    isLoadingTherapists,
    filteredTherapists,
    timeSlot,
    setTimeSlot,
    selectedTimeSlot,
    handleSubmit,
    isLoadingMutation,
    primaryContact,
  } = useClientMatchingForm(clientId, setTab);

  return (
    <div className="p-4">
      <div tw="font-semibold text-2xl mb-8">Schedule Appointment</div>
      <Form form={form} onSubmit={handleSubmit}>
        <AppointmentTypeStep />
        <SessionTypeStep appointmentType={appointmentType} />
        <FilterTherapistsStep
          canGoNext={canGoNext}
          filters={filters}
          onChangeFilters={onChangeFilters}
          autoSelectTimezone={autoSelectTimezone}
          setAutoSelectTimezone={setAutoSelectTimezone}
        />
        <EvaluationSchedulingStep
          appointmentType={appointmentType}
          initialEvaluationOptions={initialEvaluationOptions}
          onChangeEvalOptions={onChangeEvalOptions}
          filteredTherapists={filteredTherapists ?? []}
          isLoadingTherapists={isLoadingTherapists}
          autoSelectTimezone={autoSelectTimezone}
          timeSlot={timeSlot}
          setTimeSlot={setTimeSlot}
        />
        <TherapySchedulingStep
          appointmentType={appointmentType}
          sessionType={sessionType}
          filteredTherapists={filteredTherapists ?? []}
          timeSlot={timeSlot}
          setTimeSlot={setTimeSlot}
        />
        {!isNull(filteredTherapists) && !isUndefined(filteredTherapists) && (
          <NoTherapistsMessage filteredTherapists={filteredTherapists ?? []} />
        )}
        <SummaryStep
          timeSlot={timeSlot}
          selectedTimeSlot={selectedTimeSlot}
          isLoadingMutation={isLoadingMutation}
          contactTimezone={primaryContact?.contactTimeZone}
        />
      </Form>
    </div>
  );
};
