import React from 'react';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { EventProps } from 'domain/admin/types';
import { removeClientWordFromClientID } from 'utils/helpers';
import 'twin.macro';

const DischargeReviewReceived = (event: EventProps) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-discharge-review-received" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faStar} tw="mx-[5px] h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1 text-indigo-700">
            <Link to={{ pathname: `/therapists/${firehoseEvent?.therapistEmail}` }}>
              {firehoseEvent?.therapistFirstName} {firehoseEvent?.therapistLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`received a ${firehoseEvent?.reviewScore}-star discharge review for`}</span>
            &nbsp;
            <Link
              tw="text-indigo-700"
              to={{ pathname: `/clients/${removeClientWordFromClientID(firehoseEvent.clientID)}` }}
            >
              {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>
          <div>{`"${firehoseEvent?.reviewComments}"`}</div>
        </div>
      </div>
    </div>
  );
};

export default DischargeReviewReceived;
