import { presentMutationError } from '@expressable/ui-library';
import { calculateDischargeOn } from 'domain/notes/discharge/helpers';
import { NoteFormProps } from 'guards';
import { CreateNotePayload, EditNotePayload } from 'hooks/use-notes';
import { DismissPendingAppointmentPayload } from 'hooks/use-pending-appointments';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { defaultCptCodes } from 'pages/client/components/cpt-codes';
import 'twin.macro';
import { Activity, Admin, Appointment, Chart, Discharge } from 'types';

export interface BasicNoteFormProps {
  activity: Activity | null;
  activityId: string | null;
  clientId: string;
  focusElementRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
  setActivity: React.Dispatch<React.SetStateAction<Activity | null>>;
  setActivityId: React.Dispatch<React.SetStateAction<string | null>>;
  setShowNoteForm?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditForm?: React.Dispatch<React.SetStateAction<boolean>>;
  acuityId?: string | null;
  setAcuityId?: React.Dispatch<React.SetStateAction<string | null>>;
  setAutoSaveActivityId?: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface NoteResponse {
  noteID: string;
}

export interface NoteEditResponse {
  eventID: string;
}
export const onNoteSubmit = async (
  createNote: (payload: CreateNotePayload) => Promise<NoteResponse>,
  editNote: (payload: EditNotePayload) => Promise<NoteEditResponse>,
  note: Activity,
  {
    activityId,
    clientId,
    setActivity,
    setActivityId,
    setShowNoteForm,
    setShowEditForm,
    acuityId,
    setAcuityId,
    setAutoSaveActivityId,
  }: NoteFormProps,
  dismissPendingAppointment: (payload: DismissPendingAppointmentPayload) => Promise<void>,
  { autoSaving }: { autoSaving: boolean },
) => {
  if (activityId) {
    await editNote({ clientId, activityId, note })?.catch(error => {
      Sentry.captureException(error);
      presentMutationError(error, { clientId, activityId, note });
      return { eventID: '' };
    });

    if (!autoSaving) {
      // If editing started with an "Add appointment" form and
      // auto-save run at least once, we'll have the activityId
      // but not setShowEditForm (as it was not an edition form at first).
      const setShowForm = setShowEditForm || setShowNoteForm;
      setShowForm!(false);

      setActivityId(null);
    }
  } else {
    const createdNote = await createNote({ clientId, note })?.catch(error => {
      Sentry.captureException(error);
      presentMutationError(error, { clientId, activityId, note });
      return { noteID: '' };
    });

    if (!createdNote?.noteID) {
      return;
    }
    if (autoSaving) {
      setActivityId(createdNote.noteID);
      setAutoSaveActivityId!(createdNote.noteID);
    } else {
      setShowNoteForm!(false);
    }

    if (acuityId) {
      setAcuityId!(null);
    }
  }

  if (!autoSaving) {
    setActivity(null);
  }
};

export const getInitialChartNote = (): Chart => ({
  noteType: 'chart',
  note: {
    content: '',
  },
  cpt: [],
});

export const getInitialAdminNote = (): Admin => ({
  noteType: 'admin',
  note: {
    content: '',
    clientCommunication: '',
    shouldSendNoteSms: undefined,
    shouldSendNoteEmail: undefined,
  },
  cpt: [],
});

export const getInitialFreeTextNote = (): Appointment => ({
  noteType: 'appointment',
  note: {
    appointmentNoteType: 'free-text',
    content: '',
  },
  appointmentOn: moment().set({ hour: 10, minute: 0, second: 0, millisecond: 0 }),
  cpt: defaultCptCodes,
});

export const getInitialSoapNote = (): Appointment => ({
  noteType: 'appointment',
  note: {
    appointmentNoteType: 'SOAP',
    content: {
      subjectiveComplaint: {
        alert: false,
        cooperative: false,
        disengaged: false,
        engaged: false,
        'non-compliant': false,
        tired: false,
      },
      objectiveFindings: '',
      assessmentOfProgress: '',
      plansForNextSession: '',
      parentEducationConducted: '',
    },
  },
  appointmentOn: moment().set({ hour: 10, minute: 0, second: 0, millisecond: 0 }),
  cpt: defaultCptCodes,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInitialEvaluationNote = (note?: any): Appointment => ({
  noteType: 'appointment',
  version: '2.0',
  note: {
    appointmentNoteType: 'evaluation-note',
    content: {
      referralType: '',
      referredBy: '',
      clientsPrimaryLanguage: '',
      additionalLanguageExposures: '',
      intakeFormBy: '',
      medicalHistory: '',
      clinicalObservations: '',
      behavioralObservations: '',
      areFurtherAssessmentsRecommended: undefined,
      recommendedAssessments: '',
      duration: note?.detail?.duration ?? 45,
      languageSkills: [],
      diagnoses: {
        items: [],
        additionalComments: '',
      },
      carePlanGoalsProgress: [],
      visitFrequency: {
        frequency: null,
        sessionsCount: 0,
        additionalComments: '',
      },
    },
  },
  appointmentOn: note
    ? moment(note?.detail?.appointmentOn)
    : moment().set({ hour: 10, minute: 0, second: 0, millisecond: 0 }),
  cpt: [],
});

export const getInitialEvaluationNote3 = (note?: any): Appointment => ({
  noteType: 'appointment',
  version: '3.0',
  note: {
    appointmentNoteType: 'evaluation-note',
    content: {
      duration: note?.detail?.duration ?? 45,
      sourceOfLanguages: '',
    } as any,
  },
  appointmentOn: note
    ? moment(note?.detail?.appointmentOn)
    : moment().set({ hour: 10, minute: 0, second: 0, millisecond: 0 }),

  // This stub gets replaced by the proper object from the CPT list by
  // a function from the useEvaluationCPTs() hook
  cpt: [{ code: '92523', description: '' }],
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInitialSessionNote = (note?: any): Appointment => {
  return {
    noteType: 'appointment',
    note: {
      appointmentNoteType: 'session-note',
      content: {
        subjectiveComplaint: {
          alert: false,
          cooperative: false,
          disengaged: false,
          engaged: false,
          'non-compliant': false,
          tired: false,
          other: '',
        },
        parentOrCaregiverAttendSession: '',
        duration: note?.detail?.duration ?? 30,
        feedbackForClient: '',
        responseToTreatment: '',
        plansForNextSession: '',
        carePlanGoalsProgress: [],
        homePrograms: [],
      },
    },
    appointmentOn: note
      ? moment(note?.detail?.appointmentOn)
      : moment().set({ hour: 10, minute: 0, second: 0, millisecond: 0 }),
    cpt: defaultCptCodes,
  };
};

export const getInitialScreeningNote = (): Appointment => ({
  noteType: 'screening',
  note: {
    appointmentNoteType: undefined,
    content: {
      articulation: {
        intelligibility: 'good',
        soundProduction: 'no_errors',
        oralMotorFunctioning: 'normal',
      },
      language: {
        receptive: 'within_normal_limits',
        expressive: 'within_normal_limits',
      },
      fluency: 'within_normal_limits',
      inappropriateFluencyDetail: [],
      voice: 'within_normal_limits',
      recommendations: {
        overallImpressions: 'normal_limits_for_age',
        recommendation: 'no_follow_up',
        clinicalRationaleForRecommendations: '',
        additionalComments: '',
        specialtiesRequired: [],
      },
    },
  },
  appointmentOn: moment().set({ hour: 10, minute: 0, second: 0, millisecond: 0 }),
});

export const getInitialDischargeNote = (): Discharge => ({
  noteType: 'discharge',
  note: {
    content: {
      dischargeReason: '',
      mayResume: true,
      anticipatedReturn: undefined,
      againstRecommendation: undefined,
      againstRecommendationDetails: undefined,
      treatmentSummary: '',
      dischargeMessage: '',
    },
  },
  dischargeOn: calculateDischargeOn(),
});

export * from './admin-note-form';
export * from './appointment-note-form';
export * from './chart-note-form';
export * from './note-form-content/evaluation-note-form-content';
export * from './note-form-content/screening-note-form-content';
export * from './note-form-content/soap-note-form-content';

