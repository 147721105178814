import React from 'react';
import { FirehoseEventPendingReschedule } from 'domain/admin/types';
import { Link } from 'react-router-dom';
import { removeClientWordFromClientID } from 'utils/helpers';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarXmark } from '@fortawesome/free-solid-svg-icons';
import { User } from 'domain/appointment/constants';
import 'twin.macro';

const PendingRescheduleCancelled = (event: FirehoseEventPendingReschedule) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-pending-reschedule-cancelled" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center justify-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faCalendarXmark} tw="mx-1.5 h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1 text-indigo-700">
            <span tw="text-gray-500">
              {firehoseEvent?.createdBy ? (
                <Link tw="text-indigo-700" to={{ pathname: `/therapists/${firehoseEvent?.createdBy}` }}>
                  {firehoseEvent?.createdByFirstName} {firehoseEvent?.createdByLastName}
                </Link>
              ) : (
                User.Expressabot
              )}
              &nbsp;
              {`cancelled a pending reschedule for`}&nbsp;
              <Link
                tw="text-indigo-700"
                to={{ pathname: `/clients/${removeClientWordFromClientID(firehoseEvent.clientID)}` }}
              >
                {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
              </Link>
              <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
                firehoseEvent?.createdAt as string,
              ).format('MM/DD/YYYY')} `}</span>
            </span>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingRescheduleCancelled;
