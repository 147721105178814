import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';
import {
  Button,
  Card,
  FormGroup,
  FormInline,
  Input,
  Label,
  presentMutationError,
  Spacer,
  useToast,
} from '@expressable/ui-library';
import { Contact } from 'types';
import { produce } from 'immer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSaveCommunicationPreferences } from 'hooks/use-communication-preferences';

type PreferenceField = keyof Pick<
  Contact,
  | 'enableEmailNotifications'
  | 'enableSmsNotifications'
  | 'enableEmailApptReminderNotifications'
  | 'enableSMSApptReminderNotifications'
>;

interface SpecificPreferencesProps {
  contactInformation: Contact[];
  onChange: (value: Contact[]) => void;
  notificationDescription: string;
  preferenceField: PreferenceField;
  getContactDetail: (contact: Contact) => string;
  disabled?: boolean;
}

function SpecificPreferences({
  contactInformation,
  onChange,
  notificationDescription,
  preferenceField,
  getContactDetail,
  disabled = false,
}: SpecificPreferencesProps) {
  const getInputId = ({ contactID }: Contact) => `${preferenceField}_${contactID}`;

  const setPreference = (contactIndex: number, checked: boolean) => {
    onChange(
      produce(contactInformation, draft => {
        draft[contactIndex][preferenceField] = checked;
      }),
    );
  };

  return (
    <div tw="mb-8">
      <div tw="mb-2 mt-6 text-lg leading-none font-semibold">Select Contacts to Receive {notificationDescription}</div>
      <div tw="text-base text-gray-400 mb-5">Contacts not selected will not receive this notification</div>

      <FormGroup tw="mb-5">
        {contactInformation.map((contact, contactIndex) => {
          const contactHasDetail = getContactDetail(contact);
          if (contactHasDetail) {
            return (
              <FormInline key={contact.contactID} tw="mb-3 flex flex-col md:flex-row">
                <div tw="flex items-baseline">
                  <Input
                    id={getInputId(contact)}
                    data-testid={preferenceField}
                    type="checkbox"
                    spacing="tight"
                    checked={contact[preferenceField]}
                    onChange={event => setPreference(contactIndex, event.target.checked)}
                    disabled={disabled}
                    tw="mr-2"
                  />
                  <Label htmlFor={getInputId(contact)} tw="font-normal text-base">
                    {contact.contactFirstName} {contact.contactMiddleName && contact.contactMiddleName}{' '}
                    {contact.contactLastName} ({getContactDetail(contact)})
                  </Label>
                </div>
                <a href="#contacts" tw="text-indigo-700 mx-6 md:ml-5" data-testid="edit-contact">
                  Edit Contact
                </a>
              </FormInline>
            );
          }
        })}
      </FormGroup>
    </div>
  );
}

function setDefaultPreferences(contactInformation: Contact[], isAppointmentRemindersOn: boolean) {
  return produce(contactInformation, draft => {
    draft.forEach(contact => {
      isAppointmentRemindersOn
        ? (contact.enableSMSApptReminderNotifications = contact.enableSMSApptReminderNotifications ?? false)
        : null;
      isAppointmentRemindersOn
        ? (contact.enableEmailApptReminderNotifications = contact.enableEmailApptReminderNotifications ?? false)
        : null;
      contact.enableEmailNotifications = contact.enableEmailNotifications ?? true;
      contact.enableSmsNotifications = contact.enableSmsNotifications ?? false;
    });
  });
}

export default function CommunicationPreferences({
  clientID,
  clientCommunicationPreferences,
}: {
  clientID: string;
  clientCommunicationPreferences: Contact[];
}) {
  const { cueAppointmentReminders } = useFlags();
  const [currentContactInformation, setCurrentContactInformation] = useState(
    setDefaultPreferences(clientCommunicationPreferences, cueAppointmentReminders),
  );

  const { mutateAsync: updateClientCommunicationPreferences, isLoading: saving } = useSaveCommunicationPreferences({
    notifications: false,
  });

  const { successToast } = useToast();

  // Update internal state when client data is refreshed
  useEffect(
    () => setCurrentContactInformation(setDefaultPreferences(clientCommunicationPreferences, cueAppointmentReminders)),
    [clientCommunicationPreferences],
  );

  const saveClientCommunicationPreferences = async () => {
    const saveRequests = currentContactInformation.map(contact => {
      if (contact.email || Object.keys(contact.phone).length > 0) {
        return updateClientCommunicationPreferences({
          communicationPreferences: {
            contactID: contact.contactID,
            enableEmailNotifications: contact.enableEmailNotifications ?? false,
            enableEmailApptReminderNotifications: contact.enableEmailApptReminderNotifications ?? false,
            enableSMSApptReminderNotifications: contact.enableSMSApptReminderNotifications ?? false,
            enableSmsNotifications: contact.enableSmsNotifications ?? false,
          },
          clientID: clientID,
        });
      }
    });
    const responses = await Promise.allSettled(saveRequests);

    const failed = responses.filter(r => r.status === 'rejected') as PromiseRejectedResult[];
    if (failed.length > 0) {
      presentMutationError(failed[0].reason, undefined);
    } else {
      successToast('Communication Preferences Successfully Updated');
    }
  };

  return (
    <div>
      <Spacer size="md" />
      <Card tw="px-8 mb-5">
        <div tw="text-2xl mt-4 mb-9 font-semibold">Communication Preferences</div>

        {cueAppointmentReminders && (
          <>
            <SpecificPreferences
              contactInformation={currentContactInformation}
              onChange={value => setCurrentContactInformation(value)}
              notificationDescription="SMS Appointment Reminders"
              preferenceField="enableSMSApptReminderNotifications"
              getContactDetail={(contact: Contact) => contact.phone.mobile}
            />
            <SpecificPreferences
              contactInformation={currentContactInformation}
              onChange={value => setCurrentContactInformation(value)}
              notificationDescription="Email Appointment Reminders"
              preferenceField="enableEmailApptReminderNotifications"
              getContactDetail={(contact: Contact) => contact.email}
            />
          </>
        )}

        <SpecificPreferences
          contactInformation={currentContactInformation}
          onChange={value => setCurrentContactInformation(value)}
          notificationDescription="SMS Session and Evaluation Notes"
          preferenceField="enableSmsNotifications"
          getContactDetail={(contact: Contact) => contact.phone.mobile}
        />
        <SpecificPreferences
          contactInformation={currentContactInformation}
          onChange={value => setCurrentContactInformation(value)}
          notificationDescription="Email Session and Evaluation Notes"
          preferenceField="enableEmailNotifications"
          getContactDetail={(contact: Contact) => contact.email}
        />
      </Card>

      <Button
        data-testid="communication-preferences-save"
        variant="primary"
        loading={saving}
        type="button"
        onClick={saveClientCommunicationPreferences}
      >
        Save
      </Button>
    </div>
  );
}
