import { CancelledAppSource } from 'domain/appointment/constants';
import { ReferringProviderInformation } from 'domain/billing-information/types';
import { ClientAssignment } from 'domain/clinical-pathways/types';
import { Moment } from 'moment';

export interface Language {
  name: string;
  code: string;
}

export interface Therapist {
  therapistID: string;
  availableTimes: {
    [key: string]: Array<string>;
  };
  firstName: string;
  lastName: string;
  healthRecord: boolean;
  therapistName: string;
  therapistEmail: string;
  therapistProfile: string;
  sequoiaID: string;
  acceptingNewPatients: boolean;
  statesLicensed: Array<string>;
  specialties: Array<string>;
  languages: Array<Language> | null;
  acuityCalendarID: string;
  status: string;
  isPRN: boolean;
  slackUserId?: string;
  useSlackConversations: boolean;
  aiTranscriptProcessing: boolean;
  therapistTimeZone: string;
}

export interface TherapistTimesheet {
  totalDirectHours: number;
  totalDirectHoursAndMinutes: string;
  differentialHours: number;
  differentialHoursAndMinutes: string;
  committedHours: number;
  baseRateHours: number;
  medicareHours: number;
  anticipatedHours: number;
}

export const TherapistTimesheetZeroHours = '0hr';

export enum ReportingWeek {
  NewMedicaidRule = '2023-06-18',
  LegacyAnticipatedHours = '2024-03-03',
}

export const HourlyReportingDefaults = {
  anticipatedMinutesWorkedLow: 0,
  anticipatedMinutesWorkedHigh: 0,
  anticipatedHoursWorkedRange: TherapistTimesheetZeroHours,
  documentationHoursRange: TherapistTimesheetZeroHours,
  differentialHoursAndMinutes: TherapistTimesheetZeroHours,
  isLegacyAnticipatedHours: false,
  legacyAnticipatedHours: 0,
  legacyAnticipatedMinutes: 0,
  otherIndirectHoursRange: TherapistTimesheetZeroHours,
  totalDirectHoursAndMinutes: TherapistTimesheetZeroHours,
};

export enum HourlyReportingParameters {
  EvalDocumentationMinutesLow = 30,
  EvalDocumentationMinutesHigh = 60,
  SessionDocumentationMinutesLow = 3,
  SessionDocumentationMinutesHigh = 6,
  OtherPerClientMinutesLow = 5,
  OtherPerClientMinutesHigh = 8,
  OtherPerSlpMinutesLow = 45,
  OtherPerSlpMinutesHigh = 65,
}

export interface PatientScheme {
  prospectNote: string;
  assignedTo: string;
  status: string;
  ageRanges: Array<string | null>;
  email: string;
  leadTime: string;
  name: string;
  quality: string;
  state: string;
  hasSentFailedInitialOutreachText: string;
  concerns: string;
  hasSentASAPText: string;
  followUpDate?: string;
  consultDateTime: string;
  evaluationDate?: string;
  mixPanelLeadCreated: Date;
  sk: string;
  leadStatus: string;
  hasSentFailedFollowUpText: string;
  prospectType: string;
  id: string;
  pk: string;
  lastModifiedDate: string;
  phone: string;
  consultCompletedDate?: string;
  sentASAPText: string;
  sentFailedFollowUpText: string;
}

export type SelectOption<T = string> = {
  label: string;
  value: T;
};

export interface Address {
  city?: string;
  street?: string;
  line2?: string;
  postalCode?: string;
  state?: string;
}

export interface Contact {
  contactFirstName: string;
  contactMiddleName?: string;
  contactLastName: string;
  primaryContact: boolean;
  contactTimeZone: string;
  address?: Address;
  contactID: string;
  email: string;
  cognitoUserName?: string;
  cognitoCreatedAt?: string;
  faxPhone?: string | null;
  phone: {
    [key: string]: string;
  };
  relationshipToClient: string;
  enableSmsNotifications?: boolean;
  enableEmailNotifications?: boolean;
  enableEmailApptReminderNotifications?: boolean;
  enableSMSApptReminderNotifications?: boolean;
  mobilePhone?: string;
  workPhone?: string;
  homePhone?: string;
  relatedClients?: {
    contactID: string;
    relationshipToClient: string;
    clientInfo: {
      clientFirstName: string;
      clientLastName: string;
      clientID: string;
      clientPreferredFirstName: string;
    };
  }[];
}

export interface Phone {
  [key: string]: string;
}

export interface ICareTeam {
  primary: {
    therapistName: string;
    therapistEmail: string;
  };
  team?: [] | null;
}

export interface ICompleteClientInformation {
  clientFirstName: string;
  clientMiddleName: string;
  clientLastName: string;
  isClientMinor: boolean;
  billingType: string;
  stripeCustomerID: string;
  status: 'Active' | 'Inactive';
  careTeam: ICareTeam;
  contactInformation?: Contact[];
  dob: string;
  genderIdentity: string;
  preferredFirstName: string;
  sex: string;
  suffix: string;
  height?: number;
  weight?: number;
}

export interface ISingleAppointment {
  acuityAppointmentID: string;
  appointmentDateTime: string;
  appointmentDuration?: string;
  appointmentTypeID?: number;
  duration?: number;
  clientID: string;
  clientFirstName: string;
  clientLastName: string;
  calendarName?: string;
  tag: string;
}

export interface BadgeAppointmentNote {
  clientID: string;
  clientFirstName: string;
  clientLastName: string;
  count: number;
}

export interface UnlockedNoteInfo {
  clientID: string;
  clientFirstName: string;
  clientLastName: string;
  noteCreatedAt: string;
  overdueDays: number;
}

export interface AdminUnlockedNote {
  therapistName: string;
  totalCount: number;
  list: BadgeAppointmentNote[];
}

export interface ClientAttendanceVerification extends AdminUnlockedNote {
  totalCount: number;
}

export interface IClientInformation {
  clientFirstName: string;
  clientMiddleName: string;
  clientLastName: string;
  isClientMinor: boolean;
  weight?: number;
  height?: number;
  clinicalCompetencies?: string[];
  insurancePlan?: string[];
  dob?: string;
  sex?: string;
  genderIdentity?: string;
  preferredFirstName?: string;
  suffix?: string;
}

export type EventType =
  | 'add-client-diagnoses'
  | 'add-medical-diagnoses'
  | 'add-areas-of-function'
  | 'add-applicable-conditions'
  | 'add-skilled-interventions'
  | 'appointment-note'
  | 'evaluation-note'
  | 'soap-note'
  | 'chart-note'
  | 'admin-note'
  | 'intake-child'
  | 'intake-adult'
  | 'appointment-rescheduled'
  | 'appointment-cancelled'
  | 'appointment-late-cancelled'
  | 'appointment-no-show'
  | 'create-client'
  | 'intake-email-sent'
  | 'screening-intake-child'
  | 'screening-note'
  | 'add-client-goal'
  | 'session-note'
  | 'homework-submission'
  | 'add-billing-credits'
  | 'remove-billing-credits'
  | 'remove-client-diagnoses'
  | 'remove-medical-diagnoses'
  | 'remove-areas-of-function'
  | 'remove-applicable-conditions'
  | 'remove-skilled-interventions'
  | 'cancel-acuity-appointment'
  | 'reschedule-acuity-appointment'
  | 'inactivate-client'
  | 'reactivate-client'
  | 'add-acuity-appointment'
  | 'client-assignment-completed'
  | 'change-care-plan-quality-review-discharge-status'
  | 'change-care-plan-quality-review-evaluation-status'
  | 'release-of-information-form-completed'
  | 'client-files-uploaded'
  | 'files-uploaded-client'
  | 'files-deleted-client'
  | 'update-billing-info-copay';

export type EventConverterType =
  | 'chart-note'
  | 'admin-note'
  | 'appointment-note'
  | 'soap-note'
  | 'evaluation-note'
  | 'screening-note';

export type NoteConverterType = 'evaluation-note' | 'session-note';

export interface BillingCharge {
  acuityAppointmentID: string;
  amount: number;
  description: string;
  credit: {
    billingCreditID: string;
    clientID: string;
    createdAt: string;
    createdBy: string;
    status: string;
  };
  noteID: string;
  type: string;
  createdByUsername: string;
  createdAt: string;
  billingInfo: {
    automaticBillingEnabled: string;
    billingType: BillingType;
    clientID: string;
    createdAt: string;
    createdBy: string;
    membershipStatus: MembershipStatus;
    price: number;
    priceType: string;
    promptPay: PromptPay;
    sessionMinutes: number;
    stripeCustomerID: string;
    stripeAccountID: string;
    updatedAt: string;
    updatedBy: string;
  };
}

export interface RibbonProvider {
  consentToContactProvider?: boolean;
  fullName?: string;
  location?: {
    address?: string;
    addressDetails?: {
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      state?: string;
      street?: string;
      zip?: string;
    };
    clinicName?: string;
    faxes?: [
      {
        confidence: number;
        details: string;
        fax: string;
      },
    ];
    phoneNumbers?: [{ phone: string; details: string }];
  };
  npi?: string;
  phone?: string;
}

export interface Event<TDetail = { [key: string]: any }> {
  clientID: string;
  charge: BillingCharge;
  clientNoteID?: string;
  createdByDisplayName: string;
  createdBy: string;
  createdByUsername: string;
  createdAt?: string;
  createdOn: string;
  detail: TDetail;
  eventID: string;
  eventType: EventType;
  version: string;
  provider?: RibbonProvider;
  cancelDetail: {
    canceledBy: CanceledBy;
    cancelationReason: string;
    cancelationReasonOther?: string;
    wasLateCancel: boolean;
  };
  rescheduleDetail: {
    rescheduledBy: RescheduledBy | '';
    rescheduledReason: string;
  };
  hasAuthorChanged?: boolean;
}

export interface Note<TDetail = { [key: string]: unknown }> {
  activityID: string;
  clientID: string;
  createdAt: string;
  createdByDisplayName: string;
  createdByUsername: string;
  detail: TDetail;
  locked: boolean;
  noteID: string;
  noteType: string;
  sendToPortalStatus: string;
  unlockableUntil: string;
  updatedAt: string;
  version: string;
}

export interface CptCode {
  code: string;
  modifier?: string;
  description: string;
}

interface IBasicActivity {
  id?: string;
  createdByUserName?: string;
  createdByDisplayName?: string;
  createdOn?: string;
  lastEditedOn?: string;
  noteType?: string;
  locked?: boolean;
}

export interface Chart extends IBasicActivity {
  noteType: 'chart';
  note: { content: string };
  cpt?: CptCode[];
}

export interface Admin extends IBasicActivity {
  noteType: 'admin';
  note: {
    content: string;
    clientCommunication?: string;
    shouldSendNoteSms?: boolean;
    shouldSendNoteEmail?: boolean;
  };
  cpt?: CptCode[];
}

export interface Appointment extends IBasicActivity {
  appointmentOn: Moment | null;
  noteType: 'appointment' | 'screening';
  note: AppointmentNoteType;
  cpt?: CptCode[];
  version?: string;
}

export interface EmptyNoteEvaluation {
  appointmentNoteType: 'evaluation-note';
  content: Record<string, never>;
}

export interface EmptyNoteSession {
  appointmentNoteType: 'session-note';
  content: Record<string, unknown>;
}

export type EmptyNote = EmptyNoteEvaluation | EmptyNoteSession;

export interface GoalStatus {
  description: string;
  status: string;
}

export interface DischargeNoteContent {
  dischargeReason: string;
  mayResume?: boolean;
  anticipatedReturn?: string;
  againstRecommendation?: boolean;
  againstRecommendationDetails?: string;
  treatmentSummary: string;
  dischargeMessage: string;
  goalsStatuses?: Record<string, GoalStatus>;
  additionalGoalsStatuses?: Record<string, GoalStatus>;
}

export interface Discharge extends IBasicActivity {
  noteType: 'discharge';
  dischargeOn: string;
  note: {
    content: DischargeNoteContent;
  };
}

export type Activity = Chart | Admin | Appointment | Discharge;

export type ReasonsContentfulEntry = {
  label: string;
  dropdownContent: string[];
};

export type ContentfulDropdownContent = {
  label: string;
  dropdownContent: string[];
  customLogic?: any;
};

export type ContentfulStringContent = {
  label: string;
  id: string;
  content: string;
};

export type AppointmentNoteType =
  | AppointmentNoteFreeText
  | AppointmentNoteSoap
  | AppointmentNoteEvaluation
  | AppointmentNoteSession
  | ScreeningNote;

export interface AppointmentNoteFreeText {
  appointmentNoteType: 'free-text';
  content: string;
}

export interface AppointmentNoteSoap {
  appointmentNoteType: 'SOAP';
  content: SoapNoteContent;
}

export interface AppointmentNoteEvaluation {
  appointmentNoteType: 'evaluation-note';
  content: EvaluationNoteContent;
}

export interface AppointmentNoteSession {
  appointmentNoteType: 'session-note';
  content: SessionNoteContent;
}

export interface ScreeningNote {
  appointmentNoteType: undefined;
  content: ScreeningNoteContent;
}

export interface SoapNoteContent {
  subjectiveComplaint: { [key: string]: boolean };
  objectiveFindings: string;
  assessmentOfProgress: string;
  plansForNextSession: string;
  parentEducationConducted: string;
}

export interface EvaluationNoteContent {
  referralType: string;
  referredBy: string;
  clientsPrimaryLanguage: string;
  additionalLanguageExposures: string;
  intakeFormBy: string;
  medicalHistory: string;
  clinicalObservations: string;
  behavioralObservations: string;
  areFurtherAssessmentsRecommended?: boolean;
  recommendedAssessments: string;
  areSpeechTherapyServicesRecommended?: boolean;
  languageSkills?: LanguageSkill[];
  diagnoses: Diagnoses;
  carePlanGoalsProgress: GoalProgress[];
  visitFrequency: VisitFrequency;
  duration: number;
}

export type LevelInSession = 'progressing' | 'not-mastered' | 'mastered';

export interface HomePracticeNote {
  shouldAssignNewPractice?: boolean;
  shouldFocusIncompleteAssignments?: boolean;
}

export interface StructuredActivityMaterialNote {
  id: string;
  title: string;
  mediaUrl: string;
}

export interface SessionNoteContent {
  subjectiveComplaint: SubjectiveComplaint;
  parentOrCaregiverAttendSession: string;
  plansForNextSession: string;
  feedbackForClient: string;
  responseToTreatment: string;
  carePlanGoalsProgress: GoalProgress[];
  homePrograms: HomeProgramSelection[];
  cpt?: CptCode[];
  duration: number;
  therapyStrategies?: ClientAssignment[];
  homePractice?: HomePracticeNote;
  dailyRoutineActivities?: ClientAssignment[];
  structuredActivities?: ClientAssignment[];
}

export interface ScreeningNoteContent {
  articulation: {
    intelligibility: string;
    soundProduction: string;
    oralMotorFunctioning: string;
  };
  language: {
    receptive: string;
    expressive: string;
  };
  fluency: string;
  inappropriateFluencyDetail: string[];
  voice: string;
  recommendations: {
    overallImpressions: string;
    recommendation: string;
    clinicalRationaleForRecommendations: string;
    additionalComments: string;
    specialtiesRequired: string[];
  };
}

type LanguageSkill = { languageSkillType: string; observations: string } | ArticulationPhonologySkill;

export interface ArticulationPhonologySkill {
  languageSkillType: 'articulation_phonology';
  observations: '';
  articulations: Articulation[];
}

export interface Articulation {
  articulation: string;
  initialPosition: string;
  medialPosition: string;
  finalPosition: string;
}

interface Diagnoses {
  items: Diagnosis[];
  additionalComments: string;
}

export interface Diagnosis {
  isPrimaryDiagnosis?: boolean;
  code: string;
  description: string;
}

export interface MedicalDiagnosis {
  code: string;
  description: string;
}

interface GoalBaseProgress {
  goalDescription: string;
  progressLevel: number | null;
  progressUnit: string | null;
  progressComparison: string | null;
}

export type GoalType = 'ltg' | 'stg';

export interface LtgProgress extends GoalBaseProgress {
  ltgID: string;
  goalType: 'ltg';
}

export interface StgProgress extends GoalBaseProgress {
  stgID: string;
  ltgID: string;
  goalType: 'stg';
}

export type GoalProgress = LtgProgress | StgProgress;

export type Frequency = 'weekly' | 'two-weeks' | 'four-weeks' | 'monthly' | 'yearly' | null;

export interface VisitFrequency {
  frequency: Frequency;
  sessionsCount: number;
  additionalComments: string;
}

export interface SubjectiveComplaint {
  alert: boolean;
  engaged: boolean;
  cooperative: boolean;
  tired: boolean;
  disengaged: boolean;
  'non-compliant': boolean;
  other: string;
}

export interface IBasicClientFormInputs {
  clientFirstName: string;
  clientMiddleName: string;
  clientLastName: string;
  suffix: string;
  preferredFirstName: string;
  monthBirth: { [value: string]: string };
  dayBirth: { [value: string]: string };
  yearBirth: { [value: string]: string };
  genderIdentity: string;
  sex: { [value: string]: string };

  contactFirstName: string;
  contactMiddleName: string;
  contactLastName: string;
  relationshipToClient: { [key: string]: string };
  contactTimeZone: { [value: string]: string };
  email: string;
  phone: string;
  phoneKey: { [value: string]: string };
  street?: string;
  line2?: string;
  city?: string;
  state?: { [value: string]: string };
  postalCode?: string;
}

export interface IClaimForm {
  claimID: string;
  encounterID: string;
  dos: string;
  renderingProvider: SelectOption;
  npi: string;
  client: IClaimClient;
  subscriberPrimary: IClaimSubscriber;
  insuranceInformation: {
    memberID: string;
    groupNumber: string;
    planName: string;
    planType: SelectOption;
    payerName: string;
    payerID: string;
  };
  diagnoses: IDiagnosis[];
  serviceLines: IServiceLine[];
  billingNotes:
    | [
        {
          text: string;
        },
      ]
    | [];
}

interface IClaimClient {
  clientID: string;
  firstName: string;
  lastName: string;
  monthBirth: SelectOption;
  dayBirth: SelectOption;
  yearBirth: SelectOption;
  dob: string;
  gender: SelectOption;
  address: IClaimAddress;
}

interface IClaimSubscriber extends Omit<IClaimClient, 'clientID'> {
  relationshipToClient: SelectOption;
}

interface IDiagnosis {
  name: string | undefined;
  code: string;
  codeType: string;
}

interface IServiceLine {
  procedureCode: string;
  quantity: string;
  units: string;
  modifiers: SelectOption[];
  chargeAmountCents: number;
  diagnosisPointers: number[];
}

interface IClaimAddress {
  address1: string;
  address2?: string;
  city: string;
  state: SelectOption;
  zip_code: string;
}

export type BillingType = 'self-pay' | 'insurance' | '';

export type InsuranceType = 'primary' | 'secondary';

export type InsurancePayerOption = SelectOption & { id: string };

export type PromptPay = 'automatic-payments' | '';

export type MembershipStatus = 'fee-for-service' | 'monthly-membership' | '';

export type NetworkStatus = 'In-Network' | 'Out-Of-Network';

export type BillingEntityType = 'INC' | 'SLP' | 'TPC' | 'TNJ' | 'STC';

export enum BillingTypeEnum {
  selfPay = 'self-pay',
  insurance = 'insurance',
}

export enum PromptPayEnum {
  automaticPayments = 'automatic-payments',
  none = '',
}

export enum MembershipStatusEnum {
  feeForService = 'fee-for-service',
  monthlyMembership = 'monthly-membership',
}

interface IPrimaryInsuranceForm extends IInsuranceForm {
  coPay: string;
}

interface AutomaticPaymentsFeeForService {
  evaluationPrice: number;
  sessionPrice: number;
}

export interface InsuranceAuthorizations {
  evaluationAuthStatus: any;
  sessionAuthStatus: any;
  visitLimits: boolean | string;
  dateLimits: boolean | string;
  remainingVisitsLeft: number | undefined;
  visitsCompletedBy: string | undefined;
  reEvaluationRequired: boolean | string;
  reEvaluationDueBy: string | undefined;
}

export interface IBillingInfoSelfPayForm {
  promptPay: PromptPay;
  membershipStatus: MembershipStatus;
  automaticPaymentsMonthlyMembership: SelectOption;
  automaticPaymentsFeeForService: AutomaticPaymentsFeeForService;
}

export interface IBillingInfoInsuranceForm {
  networkStatus: SelectOption<NetworkStatus>;
  automaticCoInsuranceCollection: 'yes' | 'no';
  primaryInsurance: IPrimaryInsuranceForm;
  secondaryInsurance: IInsuranceForm | any;
  insuranceAuthorizations: InsuranceAuthorizations;
}

export interface IDefaultBillingInfoForm {
  automaticBillingEnabled: string;
  exemptFromPatientResponsibility: 'yes' | 'no';
  billingType: BillingType;
  stripeCustomerID: string;
  stripeAccountID: any; //eslint-disable-line
  referringProvider: ReferringProviderInformation;
  billingNotes?: string;
}

export interface IBillingInfoForm extends IDefaultBillingInfoForm, IBillingInfoSelfPayForm, IBillingInfoInsuranceForm {}

export interface IInsuranceForm {
  priorAuthorizationNumber?: string;
  coPay?: string;
  insuredRelationshipToClient: SelectOption;
  insuredFirstName: string;
  insuredMiddleName?: string;
  insuredLastName: string;
  monthBirth: SelectOption;
  dayBirth: SelectOption;
  yearBirth: SelectOption;
  insuredDob: string;
  insuredGender: SelectOption;
  insuredAddress: IBillingInfoAddressForm;
  insurancePayer: InsurancePayerOption;
  insurancePlanEligibility?: InsurancePayerOption;
  insuredPayerType: SelectOption;
  insuredMemberID: string;
  insuredGroupID: string;
}

interface IBillingInfoAddressForm {
  address1: string;
  address2?: string;
  city: string;
  state: SelectOption;
  zipCode: string;
}

export interface AcuityAppointment {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  date: string;
  time: string;
  endTime: string;
  dateCreated: string;
  datetimeCreated: string;
  datetime: string;
  type: string;
  appointmentTypeID: number;
  addonIDs: number[];
  category: string;
  duration: string;
  calendar: string;
  calendarID: number;
  confirmationPage: string;
  notes: string;
  timezone: string;
  calendarTimezone: string;
  canceled: boolean;
  noShow?: boolean;
  canClientCancel: boolean;
  canClientReschedule: boolean;
  forms: {
    id: number;
    name: string;
    values: {
      id: number;
      fieldID: number;
      value: string;
      name: string;
    }[];
  };
  formsText: string;
  override?: boolean;
  assignedTherapist?: string;
}

export interface AppointmentCancelledDetail {
  createdByUsername: string | undefined;
  createdByDisplayName: string | undefined;
  clientID: string;
  eventID: string | null;
  appointmentIDs: string[];
  cancelDetail: {
    canceledBy: CanceledBy;
    wasLateCancel?: boolean;
    cancelationReason: string;
    cancelationReasonOther?: string;
    additionalText?: string;
    rescheduleAttempted: boolean;
  };
  originalAppointmentDate: string;
  originalAppointmentDetail: AcuityAppointment;
  cancelledAppSource: CancelledAppSource;
  recurring: boolean;
  recurringForever: boolean;
  recurringSeriesBeingCanceled: boolean;
}

type AppointmentType = 'speech-therapy-session' | 'evaluation';

export interface AppointmentDetail {
  clientID: string;
  status: string;
  acuityAppointmentID: string;
  appointmentDateTime: string;
  therapistEmail: string;
  sequoiaID: string;
  therapistName: string;
  appointmentDuration?: number;
  appointmentTypeID: AppointmentType;
  clientFirstName: string;
  clientLastName: string;
}

export interface RecurringAppointment {
  calendarID: number;
  clientID: string;
  createdAt: string;
  createdBy: string;
  duration: number;
  farestFutureScheduledAppointmentDateTime: string;
  recordType: string;
  recurringAppointmentID: string;
  startDate: string;
  endDate?: string;
  therapistFirstName: string;
  therapistID: string;
  therapistLastName: string;
  updatedAt: string;
  updatedBy: string;
}

export enum CanceledBy {
  Admin = 'admin',
  Bot = 'bot',
  Client = 'client',
  Therapist = 'therapist',
}

export enum CancelationReason {
  School = 'school',
  Sick = 'sick',
  Other = 'other',
  Outage = 'outage',
  Vacation = 'vacation',
  Work = 'work',
}

export enum RescheduledBy {
  Admin = 'admin',
  Bot = 'bot',
  Client = 'client',
  Therapist = 'therapist',
}

export interface PendingAppointment extends AppointmentDetail {}

export interface UpcomingAppointmentItem {
  id: string;
  acuityID: string;
  appointmentDuration: string;
  appointmentDateTime: string;
  appointmentType: string;
  firstName: string;
  lastName: string;
  middleName: string;
  patientID: string;
  url: string;
  insuranceAuthorizations: InsuranceAuthorizations | null;
}

export interface UpcomingAppointments {
  today: UpcomingAppointmentItem[];
  tomorrow: UpcomingAppointmentItem[];
  dayThree: UpcomingAppointmentItem[];
  dayFour: UpcomingAppointmentItem[];
  dayFive: UpcomingAppointmentItem[];
  daySix: UpcomingAppointmentItem[];
  daySeven: UpcomingAppointmentItem[];
}

export interface Client {
  clientInfo: {
    clientFirstName: string;
    clientID: string;
    clientLastName: string;
  };
  relationshipToClient: string;
}

export interface ClientInfo {
  id?: string; // this makes reference to the clientID
  clientID: string;
  clientFirstName: string;
  clientLastName: string;
  clientMiddleName?: string | null;
  preferredFirstName?: string | null;
  lastAttendedVisit?: string;
  lastTherapistEmail?: string;
  lastTherapistFirstName?: string;
  lastTherapistLastName?: string;
}

export interface CompleteTherapistInformation {
  therapistEmail: string;
  sequoiaID: string;
  acuityCalendarID: string;
  firstName: string;
  preferredFirstName?: string;
  lastName: string;
  therapistTimeZone: string;
  specialties: string[];
  acceptingNewPatients: boolean;
  statesLicensed: string[];
  licenseNumbers?: string | null;
  languages: string[] | { code: string; name: string }[];
  therapistProfile: string;
  zoomLink: string;
  consultingTherapist: boolean;
  sme?: string[];
  npi?: string;
  status: string;
  committedHours: number;
  therapistType?: string;
  isPRN: boolean;
  slackUserId?: string;
  useSlackConversations: boolean;
  evaluationLimitPerDay?: number;
  evaluationLimitPerWeek?: number;
  directHourGoals?: number;
  directHourAttendanceRate?: number;
  consecutiveApptsLimit?: number;
  aiTranscriptProcessing: boolean;
  therapistState?: string;
}

export type serverValidationErrors = Array<{
  instancePath: string;
  keyword: string;
  message: string;
  schemaPath: string;
  params?: { [key: string]: string | number | Record<string, unknown>[] | serverValidationErrors };
}>;

export interface AttachmentHomeProgram {
  id: string;
  filename: string;
  url: string;
}

export interface HomeProgramSelection {
  id: string;
  type: 'contentful-homework' | 'custom';
  title: string;
  description: string;
  materials?: SelectedMaterialPayload;
  attachment?: AttachmentHomeProgram;
}

export interface CustomHomeProgramResponse {
  clientID: string;
  customHomeProgramID: string;
  title: string;
  description: string;
  createdAt: string;
  editedAt: string;
  attachment?: AttachmentHomeProgram;
}

export interface AttachmentHomeProgramResponse {
  id: string;
  file: {
    url: string;
    fileName: string;
  };
}

export interface IHomework {
  sys: {
    id: string;
  };
  title: string;
  aboutThisActivity: string;
  ageRange: string;
  complexity: string;
  timeRequired: string;
  hwType: string;
  caregiverRequired: boolean;
  associatedMaterialsIfRequired: [
    {
      fields: {
        nameOfMaterial: string;
        title: string;
        uploadMaterial: [
          {
            fields: {
              description: string;
              title: string;
              file: {
                contentType: string;
                fileName: string;
                title: string;
              };
            };
            sys: { id: string; type: string }; // reference to the contentful asset of the material
          },
        ];
        sys: { id: string; type: string }; // reference to the contentful entry of the material
      };
    },
  ];
  shortTermGoal: {
    fields: {
      shortTermGoalLabel: string;
    };
  };
}

export interface Homework {
  sys: {
    id: string;
  };
  fields: {
    title: string;
    aboutThisActivity: string;
    ageRange: string;
    hwType: string;
    caregiverRequired: boolean;
    shortTermGoal: {
      fields: {
        shortTermGoalLabel: string;
      };
    };
  };
}

export interface Insurances {
  sys: {
    id: string;
  };
  insurances: [];
}

export type SelectedMaterial = {
  homeworkId: string;
  id: string;
  name: string;
};

export type UsStates = {
  label: string;
  value: string;
  timezone: string;
};

export type SelectedMaterialPayload = Omit<SelectedMaterial, 'homeworkId'>;

export type ContentfulField = { [key: string]: string };

export type HomeworkContentfulQueryFilter = {
  ageFilter: string[];
  timeRequiredFilter: string[];
  complexityFilter: string[];
  typeFilter: string[];
  goalsFilter: string[];
  caregiverFilter: boolean | null | '';
  shortTermGoalsEntries: any[]; //eslint-disable-line
  treatmentAreaFilter?: string[];
  hasMaterials?: boolean | '';
  keywords?: string;
  limit?: number;
  skip?: number;
};

export enum AppointmentTypesNames {
  'speech-therapy-session' = 'Speech Therapy Session',
  'evaluation' = 'Evaluation',
}

export const FILTER_BY_GOAL_SELECT_OPTIONS: SelectOption[] = [
  { label: 'Active Goals', value: 'active' },
  { label: 'All Goals', value: 'all' },
  //TODO: met and discontinued goals are disabled for now
  // { label: 'Met Goals', value: 'met' },
  // { label: 'Discontinued Goals', value: 'discontinued' },
];

export interface MfaStatus {
  mfa: {
    isActive: boolean;
    activeMethod?: string;
  };
  phone?: {
    isVerified?: boolean;
    phoneNumber?: string;
  };
  error?: string;
}

export interface MfaBarcode {
  barcode: string;
  secret: string;
}

export interface MfaSendCode {
  therapistEmail: string;
  countryCode: { value: string; label: string };
  phoneNumber: string;
  signature: string;
}

export interface MfaConfirmSmsCode {
  therapistEmail: string;
  confirmationCode: string;
  session: string;
}

export interface MfaConfirmBarcode {
  therapistEmail: string;
  confirmationCode: string;
}

export interface MfaDisable {
  therapistEmail: string;
  method: string;
}

export interface MfaPublicKey {
  public_key: string;
}

export interface SlackChannelError {
  error?: { isNewRequestAvailable?: boolean; message: string; code: string };
}

export interface SlackChannelInfo extends SlackChannelError {
  channel: { id: string; name: string; member: boolean; archived: boolean; isNewRequestAvailable: boolean };
}

export interface CreateSlackChannel extends SlackChannelError {
  contactId: string;
}

export interface CreateSlackChannelInvite extends SlackChannelError {
  contactId: string;
  channelId: string;
}
